import {Caption, Headline, Subheadline} from "@telegram-apps/telegram-ui";
import React from "react";
import giftLottie from "../../../assets/lottie/gift.json";
import Lottie from "lottie-react";
import WinnerTable from "../../../pages/Home/WinnerTable";
import Drawer from "../Drawer";
import {formatNumber} from "../../../helpers/format_helper";
import Prizes from "./Parts/Prizes";

const ModalRaffleFinished = (props) => {
    const {isOpen, modalClose, selectedRaffle} = props

    return (
        <Drawer
            isOpen={isOpen}
            onClose={modalClose}
        >
            <div className={"d-flex w-100 flex-column gap-3 p-3 justify-content-center align-items-center"}>
                <div className={"task-icon"}>
                    <Lottie
                        style={{
                            width: 160,
                            height: 80
                        }}
                        animationData={giftLottie} loop={false}
                    />
                </div>
                <div className={"d-flex flex-row gap-3 align-items-center"}>
                    <Prizes prizes={selectedRaffle?.prizes} />
                    <span
                        className={"ton_num"}
                    >
                        👥 {formatNumber(selectedRaffle?.current_participants)}
                    </span>
                </div>

                <div className={"text-center"}>
                    <Headline
                        className={"text-center"}
                        level="1"
                        weight="2"
                    >
                        List of winners
                    </Headline>
                    <Caption className={"subtitle-color"}>{selectedRaffle?.updated_at}</Caption>
                </div>
                <WinnerTable
                    selectedRaffle={selectedRaffle}
                />
            </div>
        </Drawer>
    )
}

export default ModalRaffleFinished