import React, {useMemo, useState} from 'react'
import Lottie from "lottie-react";
import rewardsLottie from "../../assets/lottie/rewards.json";
import {Button, Headline, Title} from "@telegram-apps/telegram-ui";
import {Wheel} from "react-custom-roulette";
import PrizeWheel from "../../Components/custom/PrizeWheel";
import Tasks from "./Tasks";
const Rewards = () => {

    return (
        <div className={"p-2"}>
            <div
                className={"d-flex justify-content-center gap-2 flex-column align-items-center text-center mb-3"}>
                <Lottie
                    style={{
                        width: 150,
                        height: 100
                    }}
                    animationData={rewardsLottie} loop={true}
                />
                <Title
                    weight={3}
                >
                    Daily Rewards
                </Title>
                <Headline>
                    Claim your free gift every day and keep the streak going!
                </Headline>
            </div>
            <div className={"d-flex flex-column"}>
                <Tasks />
                <PrizeWheel />
            </div>
        </div>
    )
}

export default Rewards