import {ModalHeader} from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import {ModalClose} from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose";
import {Icon28Close} from "@telegram-apps/telegram-ui/dist/icons/28/close";
import Lottie from "lottie-react";
import lottieRoulette from "../../assets/lottie/roulette.json";
import {Button, Card, Headline, Image, Modal, Subheadline, Title, Text} from "@telegram-apps/telegram-ui";
import React, {useState} from "react";
import useTaskIcon from "../../hooks/useTaskIcon";
import {checkTaskApi} from "../../helpers/real_api_helper";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {setWelcomeModal} from "../../slices/app/reducer";
import starsLottie from "../../assets/lottie/stars.json";
import dice from "../../assets/lottie/dice.json";
import dicelot from "../../assets/lottie/dotlottie/dice.lottie";
import tasks from "../../assets/lottie/dotlottie/tasks.lottie";
import welcome from "../../assets/lottie/dotlottie/welcome.lottie";
import coin from "../../assets/lottie/dotlottie/coin.lottie";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import Drawer from "./Drawer";

const ModalWelcome = () => {
    const {showWelcomeModal} = useSelector(state => state.App)
    const dispatch = useDispatch()
    const modalClose = () => {
        dispatch(setWelcomeModal(false))
    }

    return (
        <Drawer
            isOpen={showWelcomeModal}
            onClose={modalClose}
        >
           <div className={"d-flex flex-column gap-2 justify-content-center align-items-center p-4 pt-0"}>
               <div className={"welcome-icon"}>
                   <DotLottieReact
                       style={{
                           width: 150,
                           height: 75
                       }}
                       src={welcome}
                       loop
                       autoplay
                   />
               </div>
               <div className={"d-block mb-2"}>
                   <Title weight={1}>Welcome to Lotty</Title>
               </div>
               <div className={"d-flex gap-2 flex-column"}>
                   <Card className={"p-3 d-flex"}>
                       <div className={"d-block"}>
                           <DotLottieReact
                               style={{
                                   width: 150,
                                   height: 75
                               }}
                               src={dicelot}
                               loop
                               autoplay
                           />
                       </div>
                       <div className={"d-block"}>
                           <Headline weight={1}>Short Lotteries</Headline>
                           <Subheadline>Participate in exciting lotteries with real cryptocurrency prizes!</Subheadline>
                       </div>
                   </Card>
                   <Card className={"p-3 d-flex"}>
                       <div className={"d-block"}>
                           <DotLottieReact
                               style={{
                                   width: 150,
                                   height: 75
                               }}
                               src={coin}
                               loop
                               autoplay
                           />
                       </div>
                       <div className={"d-block"}>
                           <Headline weight={1}>In-App Currency</Headline>
                           <Subheadline>Earn in-game currency by participating in games and tasks!</Subheadline>
                       </div>
                   </Card>
                   <Card className={"p-3 d-flex"}>
                       <div className={"d-block"}>
                           <DotLottieReact
                               style={{
                                   width: 150,
                                   height: 75
                               }}
                               src={tasks}
                               loop
                               autoplay
                           />
                       </div>
                       <div className={"d-block"}>
                           <Headline weight={1}>Income Tasks</Headline>
                           <Subheadline>Complete daily and ongoing tasks to boost your winnings!</Subheadline>
                       </div>
                   </Card>
               </div>
               <div className={"d-block mt-2 text-center subtitle-color"}>
                   <Text>
                       In our app, you can join short lotteries with real prizes, earn internal currency, and convert it to cryptocurrency. Start earning right now!
                   </Text>
               </div>
               <div className={"d-block w-100 mt-2"}>
                   <Button
                       stretched
                   >
                       Got It
                   </Button>
               </div>
           </div>
        </Drawer>
    )
}

export default ModalWelcome