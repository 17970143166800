import Home from "../pages/Home";
import {Navigate} from "react-router-dom";
import Profile from "../pages/Profile";
import Rewards from "../pages/Rewards";
import Friends from "../pages/Friends";
import Converter from "../pages/Converter";
import Withdraw from "../pages/Withdraw";
import Top100 from "../pages/Top100";

export const authProtectedRoutes = [
    {path: '/lottys', component: <Home/>},
    {path: '/wallet', component: <Profile/>},
    {path: '/rewards', component: <Rewards/>},
    {path: '/friends', component: <Friends/>},
    {path: '/converter', component: <Converter/>},
    {path: '/withdraw', component: <Withdraw/>},
    {path: '/top100', component: <Top100/>},
    {
        path: "/",
        exact: true,
        component: <Navigate to="/lottys" />,
    },
    { path: "*", component: <Navigate to="/lottys" /> },
]