import giftLottie from "../../../assets/lottie/gift.json";
import Lottie from "lottie-react";
import React from "react";
import {Badge, Button, Caption, Card, Subheadline, Text} from "@telegram-apps/telegram-ui";
import Prizes from "./Parts/Prizes";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import chuvak from "../../../assets/lottie/dotlottie/chuvak.lottie";
import {formatNumber} from "../../../helpers/format_helper";
import {setSelectedRaffle} from "../../../slices/raffles/reducer";
import {useDispatch} from "react-redux";
import {checkIsNew} from "../../../helpers/raffle_helper";

const HorizontalLotteryCard = (props) => {
    const {current} = props
    const dispatch = useDispatch()
    const setSelected = (el) => {
        dispatch(setSelectedRaffle(el))
    }
    return (
        <Card
            style={{
                borderRadius: 8
            }}
            className={"d-flex flex-column gap-2 p-3 justify-content-between"}>
            <div className={"d-flex gap-1 "}>
                <div className={"lotty-icon"}>
                    <Lottie
                        style={{
                            width: 80,
                            height: 60
                        }}
                        animationData={giftLottie} loop={false}
                    />
                </div>
                <div className={"d-flex flex-column gap-1 w-100"}>
                    <div className={"d-flex justify-content-between align-items-center"}>
                        <div className={"d-flex gap-1"}>
                            <Subheadline
                                weight={2}
                            >
                                Lotty #{current?.id}
                            </Subheadline>
                            {checkIsNew(current)}
                        </div>
                        {current?.is_participating &&
                            <Badge type={"number"}>
                            Chance: x{formatNumber(current?.raffle_chance)}
                        </Badge>
                        }

                    </div>

                    <div className={"d-flex flex-row gap-3"}>
                        <Prizes prizes={current.prizes} />
                    </div>
                    <div className={"d-flex flex-row text-center align-items-center gap-1"}>
                        <DotLottieReact
                            src={chuvak}
                            loop
                            autoplay
                            style={{
                                height: 15,
                                width: 15,
                            }}
                        />
                        <span
                            className={"ton_num"}
                        >
                            {formatNumber(current?.current_participants)} / {formatNumber(current?.max_participants)}
                        </span>

                    </div>
                </div>

            </div>
            <div className={"card-button"}>
                <Button
                    mode={"bezeled"}
                    size={'l'}
                    style={{
                        height: 'inherit'
                    }}
                    stretched
                    onClick={() => {setSelected(current)}}
                >
                    <Text weight={2}>
                        {current?.is_participating ?  'Joined Up' : 'Join Lotty'}
                    </Text>

                </Button>
            </div>
        </Card>
    )
}

export default HorizontalLotteryCard