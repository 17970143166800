import IconTon from "../../Components/icons/IconTon";
import IconLottyStar from "../../Components/icons/IconLottyStar";
import first from "../../assets/lottie/dotlottie/1st.lottie";
import second from "../../assets/lottie/dotlottie/2nd.lottie";
import third from "../../assets/lottie/dotlottie/3rd.lottie";
import dollar from "../../assets/lottie/dotlottie/dollar.lottie";
import star from "../../assets/lottie/dotlottie/star.lottie";
import crown from "../../assets/lottie/dotlottie/crown.lottie";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { formatNumber } from "../../helpers/format_helper";
import IconLottyCoin from "../../Components/icons/IconLottyCoin";
import {getLottieIcon} from "../../helpers/raffle_helper";

const PrizeTable = ({ matrix }) => {
    // Функция для парсинга диапазона
    const parseRange = (range) => {
        const [start, end] = range.split('-').map(Number);
        return start === end ? `${start}` : `${start} - ${end}`;
    };

    // Определение иконки для типа приза
    const getIconForType = (type) => {
        switch (type) {
            case "ton":
                return <IconTon className="ton" />;
            case "star":
                return <IconLottyStar style={{ height: 15 }} />;
            case "coin":
                return <IconLottyCoin style={{ height: 15 }} />; // Можно поменять на иконку для coin, если есть
            default:
                return null;
        }
    };

    // Генерация анимации для первых трех мест
    const getLottieForIndex = (index) => {
        switch (index) {
            case 0:
                return (
                    <DotLottieReact
                        src={first}
                        loop
                        autoplay
                        style={{
                            height: 24,
                            width: 33,
                        }}
                    />
                );
            case 1:
                return (
                    <DotLottieReact
                        src={second}
                        loop
                        autoplay
                        style={{
                            height: 24,
                            width: 33,
                        }}
                    />
                );
            case 2:
                return (
                    <DotLottieReact
                        src={third}
                        loop
                        autoplay
                        style={{
                            height: 24,
                            width: 33,
                        }}
                    />
                );
            default:
                return null;
        }
    };

    // Генерация строк таблицы на основе данных
    const renderPrizeRows = () => {
        return matrix.map((item, index) => (
            <tr key={`prize-${index}`}>
                <td className={"d-flex flex-row-reverse"}>
                    {parseRange(item.range)}
                    {index < 3 ? getLottieForIndex(index) : getLottieIcon(item.type)}
                </td>
                <td className="d-flex align-items-center gap-1">
                    {formatNumber(item.prize)}
                    {getIconForType(item.type)}
                </td>
            </tr>
        ));
    };

    return (
        <div className="fiat-winner-table">
            <table>
                <tbody>{matrix && renderPrizeRows()}</tbody>
            </table>
        </div>
    );
};

export default PrizeTable;