import {Badge, Button, Caption, Card, Subheadline, Text} from "@telegram-apps/telegram-ui";
import Lottie from "lottie-react";
import giftLottie from "../../assets/lottie/gift.json";
import IconTon from "../icons/IconTon";
import IconLottyStar from "../icons/IconLottyStar";
import React from "react";
import {formatNumber} from "../../helpers/format_helper";
import {useDispatch} from "react-redux";
import {setSelectedRaffle} from "../../slices/raffles/reducer";
import Prizes from "./Raffle/Parts/Prizes";
import chuvak from '../../assets/lottie/dotlottie/chuvak.lottie'
import icon_new from "../../assets/lottie/dotlottie/new.lottie";
import icon_soon from "../../assets/lottie/dotlottie/soon.lottie";
import second from "../../assets/lottie/dotlottie/2nd.lottie";
import third from "../../assets/lottie/dotlottie/3rd.lottie";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import MemoDotLottie from "./MemoDotLottie";
import first from "../../assets/lottie/dotlottie/1st.lottie";
import {checkIsNew} from "../../helpers/raffle_helper";

const LotteryCard = (props) => {
    const {current} = props

    const dispatch = useDispatch()

    const setSelected = (el) => {
        dispatch(setSelectedRaffle(el))
    }

    return (
        <Card
            onClick={() => {setSelected(current)}}
            className={"d-flex align-items-center flex-column gap-2 pb-3 px-3 cursor-pointer w-100 lotty-card"}
            type={"plain"}
        >
            {/*<div className={"d-flex justify-content-end align-items-center w-100 mt-3 position-absolute"}>*/}
            {/*    <Badge*/}
            {/*        className={"card-badge"}*/}
            {/*        type={"number"}*/}
            {/*        mode={"dark"}*/}
            {/*    >*/}
            {/*        #{current?.id}*/}
            {/*    </Badge>*/}
            {/*</div>*/}
            <Lottie
                style={{
                    width: 160,
                    height: 80
                }}
                animationData={giftLottie} loop={false}
            />
            <div className={"d-flex gap-1"}>
                <Subheadline
                    weight={2}
                >
                    Lotty #{current?.id}
                </Subheadline>
                {checkIsNew(current)}
            </div>

            <div
                style={{
                    minHeight: 78
                }}
                className={"d-flex flex-column justify-content-center align-items-center"}>
                <Prizes prizes={current.prizes} />
            </div>
            {/*<div className={"d-flex flex-column text-center align-items-center"}>*/}
            {/*    <Subheadline weight={2}>*/}
            {/*        Consolation Prize*/}
            {/*    </Subheadline>*/}
            {/*    <div className={"d-flex gap-1 align-items-center"}>*/}
            {/*        <IconLottyStar style={{*/}
            {/*            height: 15*/}
            {/*        }} />*/}
            {/*        <span*/}
            {/*            className={"ton_num"}*/}
            {/*        >{formatNumber(current.consolation_prize)}</span>*/}
            {/*    </div>*/}

            {/*</div>*/}
            <div className={"d-flex flex-row text-center align-items-center gap-1"}>
                <DotLottieReact
                    src={chuvak}
                    loop
                    autoplay
                    style={{
                        height: 20,
                        width: 20,
                    }}
                />
                <span className={"ton_num"}>
                    {formatNumber(current?.current_participants)} / {formatNumber(current?.max_participants)}
                </span>
            </div>

            <div className={"mt-2"}>
                <Button
                    mode={"bezeled"}
                    size={'l'}
                    style={{
                        height: 'inherit'
                    }}
                    stretched
                    onClick={() => {setSelected(current)}}
                >
                    <Text weight={2}>
                        {current?.is_participating ?  'Joined Up' : 'Join Lotty'}
                    </Text>
                </Button>
            </div>
        </Card>
    )
}

export default LotteryCard