import React, {useState} from 'react'
import {Badge, Button, Caption, Card, Headline, Subheadline, TabsList, Text, Title} from "@telegram-apps/telegram-ui";
import Lottie from "lottie-react";
import giftLottie from '../../assets/lottie/gift.json'
import starsLottie from '../../assets/lottie/stars.json'
import RaffleList from "../../Components/custom/RaffleList";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedRaffle} from "../../slices/raffles/reducer";
import TopWinners from "./TopWinners";
import TopRaffles from "./TopRaffles";
const Home = () => {

    const dispatch = useDispatch()
    const {selectedRaffle} = useSelector(state => state.Raffles)


    return (
        <React.Fragment>

            {/*<div className={"d-flex justify-content-center gap-2 flex-column align-items-center text-center mb-3"}>*/}
            {/*    <Lottie*/}
            {/*        style={{*/}
            {/*            width: 150,*/}
            {/*            height: 100*/}
            {/*        }}*/}
            {/*        animationData={starsLottie} loop={true}*/}
            {/*    />*/}
            {/*    <Title*/}
            {/*        weight={3}*/}
            {/*    >*/}
            {/*        Win Stars and Enter the Lottery!*/}
            {/*    </Title>*/}
            {/*    <Headline>*/}
            {/*        Earn stars to join lotteries and win exciting rewards.*/}
            {/*    </Headline>*/}
            {/*</div>*/}
            <TopWinners />
            <RaffleList />

        </React.Fragment>
    )
}

export default Home