import IconTon from "../../../icons/IconTon";
import {formatNumber} from "../../../../helpers/format_helper";
import IconLottyStar from "../../../icons/IconLottyStar";
import React from "react";
import IconLottyCoin from "../../../icons/IconLottyCoin";

const Prizes = ({prizes}) => {
    return (
        <>
            {prizes && prizes.map((el, i) => {
                switch (el.name) {
                    case "TON":
                        return (
                            <div key={i} className={"d-flex gap-1"}>
                                <IconTon className={"ton"}/>
                                <span
                                    className={"ton_num"}
                                >{formatNumber(el.amount)}</span>
                            </div>
                        )
                    case "Stars" :
                        return (
                            <div key={i} className={"d-flex gap-1 align-items-center"}>
                                <IconLottyStar
                                    style={{
                                        height: 15
                                    }}
                                />
                                <span
                                    className={"ton_num"}
                                >{formatNumber(el.amount)}</span>
                            </div>
                        )
                    case "Coins" :
                        return (
                            <div key={i} className={"d-flex gap-1 align-items-center"}>
                                <IconLottyCoin
                                    style={{
                                        height: 15
                                    }}
                                />
                                <span
                                    className={"ton_num"}
                                >{formatNumber(el.amount)}</span>
                            </div>
                        )
                }
            })}
        </>
    )
}

export default Prizes