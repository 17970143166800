import {Avatar, Button, Caption, Card, Headline, LargeTitle, Pagination, Section, Subheadline, Title} from "@telegram-apps/telegram-ui";
import React from 'react'
import Lottie from "lottie-react";
import coinsLottie from "../../assets/lottie/coins.json";
import starCoinLottie from "../../assets/lottie/starcoin.json";
import {GiAndroidMask, GiEmerald, GiRoundStar} from "react-icons/gi";
import {Link, useNavigate} from "react-router-dom";
import IconTon from "../../Components/icons/IconTon";
import IconLottyStar from "../../Components/icons/IconLottyStar";
import {TonConnectButton} from "@tonconnect/ui-react";
import {useDispatch} from "react-redux";
import {setCurrentTabbarItem} from "../../slices/app/reducer";
import WalletList from "./WalletList";
import TransactionsList from "./TransactionsList";

const Profile = () => {

    return (
        <div className={"p-2"}>
            <div
                className={"d-flex justify-content-center gap-2 flex-column align-items-center text-center mb-3"}>
                <Lottie
                    style={{
                        width: 150,
                        height: 100
                    }}
                    animationData={coinsLottie} loop={true}
                />
                <Title
                    weight={3}
                >
                    Wallet
                </Title>
                <Headline>
                    Collect Stars, Convert to Ton or Lotty!
                </Headline>
            </div>

            <WalletList />
            <TransactionsList />

        </div>
    )
}

export default Profile