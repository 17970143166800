import {authTgUser} from "../../helpers/real_api_helper";
import {setCookie} from "../../helpers/cookie_helper";
import {loginSuccess} from "./reducer";
import {getCounters} from "../app/thunk";

export const authUser = (data, unsafeData) => async (dispatch) => {
    try {
        let json = {
            query_string: data
        }
        authTgUser(json)
            .then(async (res) => {
                let token = res.data.token
                localStorage.setItem('authToken', token)
                await dispatch(loginSuccess(res.data.user))
            })
            .catch((error) => {
                console.log(error)
            })

    }
    catch (error) {
        console.log(error)
    }
}