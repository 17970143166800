import {Badge, Caption, Card, Headline, LargeTitle, Subheadline} from "@telegram-apps/telegram-ui";
import React from "react";
import IconTon from "../../icons/IconTon";
import IconLottyStar from "../../icons/IconLottyStar";
import giftLottie from "../../../assets/lottie/gift.json";
import Lottie from "lottie-react";
import WinnerTable from "../../../pages/Home/WinnerTable";
import Drawer from "../Drawer";
import {formatNumber} from "../../../helpers/format_helper";
import {getPrize} from "../../../helpers/raffle_helper";
import Prizes from "./Parts/Prizes";
import IconLottyCoin from "../../icons/IconLottyCoin";

const ModalRaffleWinner = (props) => {
    const {isOpen, modalClose, selectedRaffle} = props

    const current_user_winner = selectedRaffle?.current_user_win
    let prize_icon
    switch (current_user_winner.prize.type){
        case 'ton':
            prize_icon =  <IconTon className="ton-icon-winner" />
            break
        case 'star':
            prize_icon = <IconLottyStar
                style={{
                    height: 30,
                    width: 20
                }}
            />
            break
        case 'coin':
            prize_icon = <IconLottyCoin
                style={{
                    height: 30,
                    width: 20
                }}
            />
            break
        default:
            prize_icon = ''
    }
    return (
        <Drawer
            isOpen={isOpen}
            onClose={modalClose}
        >
            <div className={"d-flex w-100 flex-column gap-3 p-3 pb-0 justify-content-center align-items-center"}>
                <div className={"task-icon"}>
                    <Lottie
                        style={{
                            width: 160,
                            height: 80
                        }}
                        animationData={giftLottie} loop={false}
                    />
                </div>
                <div className={"d-flex flex-row gap-3 align-items-center"}>
                    <Prizes prizes={selectedRaffle?.prizes} />
                    <span className={"ton_num"}>
                        👥 {formatNumber(selectedRaffle?.current_participants)}
                    </span>

                </div>
                <div className={"d-flex gap-2"}>
                    <Card className={"p-3 d-flex flex-column align-items-center gap-1"}>
                        <LargeTitle weight={1}>{current_user_winner.place}</LargeTitle>
                        <Caption className={"subtitle-color"}>Your winner place</Caption>
                    </Card>
                    <Card style={{minWidth: 139.6}} className={"p-3 d-flex flex-column align-items-center gap-1"}>
                        <LargeTitle weight={1} className={"d-flex align-items-end gap-2"}>
                            {formatNumber(current_user_winner.amount)} {prize_icon}
                        </LargeTitle>
                        <Caption className={"subtitle-color"}>Your prize</Caption>
                    </Card>
                </div>
                <div className={"text-center"}>
                    <Headline
                        className={"text-center"}
                        level="1"
                        weight="2"
                    >
                        List of winners
                    </Headline>
                    <Caption className={"subtitle-color"}>{selectedRaffle?.updated_at}</Caption>
                </div>
                <WinnerTable
                    selectedRaffle={selectedRaffle}
                />
            </div>
            <div
                className={"current-winner p-3 px-4 d-flex w-100 justify-content-between"}
            >
                <div className={"d-flex gap-1"}>
                    <span className={"d-flex"}>{current_user_winner?.place}.</span>
                    <span>
                        @{current_user_winner.user.username}
                        <Badge type={"number"}>You</Badge>
                    </span>
                </div>
                <div>
                    {getPrize(current_user_winner.prize, current_user_winner.amount)}
                </div>
            </div>
        </Drawer>
    )
}

export default ModalRaffleWinner