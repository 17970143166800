import React, { useEffect, useState, useRef } from "react";
import {Avatar, Button, FixedLayout, IconButton, Input, Modal, Title} from "@telegram-apps/telegram-ui";
import { CgArrowsExchangeAltV } from "react-icons/cg";
import IconTon from "../../Components/icons/IconTon";
import IconLottyStar from "../../Components/icons/IconLottyStar";
import ModalConfirm from "./ModalConfirm";
import Drawer from "../../Components/custom/Drawer";
import Select from '@mui/material/Select';
import { MenuItem } from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {convertTokensApi} from "../../helpers/real_api_helper";
import {toast} from "react-toastify";
import {setReward} from "../../slices/app/reducer";
import {getBalance} from "../../slices/balance/thunk";
import {getTransactions} from "../../slices/transactions/thunk";
import { Howl } from 'howler';
import IconLottyCoin from "../../Components/icons/IconLottyCoin";

const ModalConverter = (props) => {
    const inputRef = useRef(null);
    const contentRef = useRef(null);
    const { isOpen, modalClose } = props;

    const dispatch = useDispatch()
    const {balance} = useSelector(state => state.Balance)

    const currencies = [
        {name: "star", short_name: "str"},
        {name: "ton", short_name: "ton"},
        {name: "coin", short_name: "lty"}
    ]
    const defaultFromOptions = [
        { name: "STR", icon: <IconLottyStar className={"star-convert-icon"} />, value: 'str' },
        { name: "TON", icon: <IconTon className={"ton-convert-icon"} />, value: 'ton' },
    ];

    const defaultToOptions = [
        { name: "TON", icon: <IconTon className={"ton-convert-icon"} />, value: 'ton' },
        { name: "LTY", icon: <IconLottyCoin className={"star-convert-icon"} />, value: 'lty' },
    ];

    const starPrice = 0.0001;
    const coinPrice = 97.3;
    const [exchangeFrom, setExchangeFrom] = useState('str');
    const [exchangeTo, setExchangeTo] = useState('ton');
    const [exchangeToOptions, setExchangeToOptions] = useState(defaultToOptions);
    const [exchangeFromOptions, setExchangeFromOptions] = useState(defaultFromOptions);
    const [currentFrom, setCurrentFrom] = useState(0);
    const [currentTo, setCurrentTo] = useState(0);
    const [disabledReverse, setDisabledReverse] = useState(false);
    const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
    const [currentCourse, setCurrentCourse] = useState(starPrice);


    const playSound = () => {
        let sound;
        sound = new Howl({
            src: ['/audio/money.mp3']
        });
        sound.play();
    }
    const convert = () => {
        let from = currencies.find(el => el.short_name === exchangeFrom)
        let to = currencies.find(el => el.short_name === exchangeTo)
        let data = {
            from: from.name,
            to: to.name,
            amount: currentFrom
        }
        convertTokensApi(data)
            .then(res => {
                modalClose();
                dispatch(getBalance())
                dispatch(getTransactions())
                let reward = [
                    {
                        reward_type: from.name,
                        amount: currentFrom,
                        transaction_type: 'debit'
                    },
                    {
                        reward_type: to.name,
                        amount: currentTo,
                        transaction_type: 'credit'
                    }

                ]
                dispatch(setReward(reward))
                playSound()
            })
            .catch(err => {
                toast.warning(err?.data?.message)
            })

    };


    const openConfirmationWindow = () => {
        const MainButton = window.Telegram.WebApp.MainButton
        window.Telegram.WebApp.showPopup({
            message : 'Are you sure?',
            buttons: [
                {type: "ok", id: 'ok'},
                {type: "cancel", id: "cancel"}
            ]
        })
    }

    const calculateCourse = (from, to) => {
        let course;
        if (from === 'str' && to === 'lty') course = coinPrice;
        else if (from === 'str' && to === 'ton') course = starPrice;
        else if (from === 'ton' && to === 'str') course = 10000;
        else if (from === 'ton' && to === 'lty') course = 10000 * coinPrice;
        return course;
    };

    useEffect(() => {
        const handlePopup = (res) => {
            if (res == 'ok')
                convert()
        }
        window.Telegram.WebApp.onEvent('popupClosed', handlePopup);
        window.Telegram.WebApp.offEvent('popupClosed', handlePopup);
    }, [currentFrom, exchangeFrom, exchangeTo, currentTo]);

    useEffect(() => {
        const handleMainButtonClick = () => {
            openConfirmationWindow();
        };

        window.Telegram.WebApp.onEvent('mainButtonClicked', handleMainButtonClick);

        // Cleanup the event listener on unmount
        return () => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainButtonClick);
        };
    }, [currentFrom, exchangeFrom, exchangeTo, currentTo]);

    useEffect(() => {
        const MainButton = window.Telegram.WebApp.MainButton
        if (isOpen) {
            MainButton.enable()
            MainButton.setText('Convert')
            MainButton.show()
        }
        else
            MainButton.hide()
    }, [isOpen]);

    useEffect(() => {
        let course = calculateCourse(exchangeFrom, exchangeTo);
        if (course) setCurrentCourse(course);
    }, [exchangeFrom, exchangeTo]);

    useEffect(() => {
        if (exchangeFrom === 'str') {
            setExchangeToOptions(defaultToOptions.filter(option => option.value !== 'str'));
        } else if (exchangeFrom === 'ton') {
            setExchangeToOptions([
                { name: "STR", icon: <IconLottyStar className={"star-convert-icon"} />, value: 'str' },
                { name: "LTY", icon: <IconLottyStar className={"star-convert-icon"} />, value: 'lty' }
            ]);
        }

        setExchangeFromOptions(defaultFromOptions.filter(option => option.value !== exchangeTo));
    }, [exchangeFrom, exchangeTo]);

    useEffect(() => {
        setDisabledReverse(false);
        if (exchangeTo === 'lty') setDisabledReverse(true);
    }, [exchangeTo]);


    const handleExchangeTo = (e) => {
        setExchangeTo(e.target.value);
    };

    const handleReverse = () => {
        const newExchangeFrom = exchangeTo;
        const newExchangeTo = exchangeFrom;
        setExchangeFrom(newExchangeFrom);
        setExchangeTo(newExchangeTo);
    };

    const handleExchangeFrom = (e) => {
        setExchangeFrom(e.target.value);
    };

    const setMaxAmount = () => {
        let maxAmount
        switch(exchangeFrom) {
            case 'str':
                maxAmount = balance.star_balance
                break
            case 'ton':
                maxAmount = balance.ton_balance
                break
            default:
                maxAmount = balance.star_balance
        }
        setCurrentFrom(maxAmount)
    }

    useEffect(() => {
        let converted = Number(currentFrom * currentCourse)
        setCurrentTo(converted);
    }, [currentFrom, currentCourse]);

    return (
        <>
            <ModalConfirm
                convert={convert}
                isOpen={modalConfirmOpen}
                modalClose={() => {
                    setModalConfirmOpen(false);
                }}
            />
            <Drawer
                isOpen={isOpen} onClose={modalClose}>
                <div
                    ref={contentRef}
                    className={"d-block"}>
                    <div
                        className={"d-flex justify-content-center align-items-center flex-column p-3"}>
                        <div className={"d-flex mb-4"}>
                            <Title weight={1}>Converter</Title>
                        </div>

                        <div className={"converter d-flex w-100 flex-column gap-4 justify-content-center align-items-center"}>
                            <div className={"w-100 d-flex align-items-center gap-4 justify-content-center convert-input-div"}>
                                <Input
                                    ref={inputRef}
                                    status={"default"}
                                    type={"number"}
                                    inputMode="decimal"
                                    onChange={(e) => setCurrentFrom(e.target.value)}
                                    value={currentFrom}
                                    className={"convert-input w-100"}
                                    after={
                                        <Button size={"s"} onClick={setMaxAmount} mode={"plain"}>Max</Button>
                                    }
                                    id="input-field"
                                />
                                <div className={"select-currency"}>
                                    <Select id="exchange-from" value={exchangeFrom} onChange={handleExchangeFrom}>
                                        {exchangeFromOptions.map((el, i) => (
                                            <MenuItem key={i} value={el.value}>
                                                <div className={"d-flex gap-1 align-items-center"}>
                                                    {el.icon}
                                                    <span className={"subtitle-color"}>{el.name}</span>
                                                </div>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </div>

                            <div className={"convert-icon d-flex align-items-center"}>
                                <IconButton disabled={disabledReverse} onClick={handleReverse}>
                                    <CgArrowsExchangeAltV size={26} />
                                </IconButton>
                            </div>

                            <div className={"w-100 d-flex align-items-center gap-4 justify-content-center convert-input-div"}>
                                <Input
                                    disabled
                                    status={"default"}
                                    type={"number"}
                                    value={currentTo}
                                    className={"convert-input"}
                                />
                                <div className={"select-currency"}>
                                    <Select id="exchange-to" value={exchangeTo} onChange={handleExchangeTo}>
                                        {exchangeToOptions.map((el, i) => (
                                            <MenuItem key={i} value={el.value}>
                                                <div className={"d-flex gap-1 align-items-center"}>
                                                    {el.icon}
                                                    <span className={"subtitle-color"}>{el.name}</span>
                                                </div>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>
        </>
    );
};

export default ModalConverter;