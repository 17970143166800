import {authTgUser, getBalanceApi, getCountsApi} from "../../helpers/real_api_helper";
import {setCookie} from "../../helpers/cookie_helper";
import {setBalance, setCounters} from "./reducer";
import {loginSuccess} from "../auth/reducer";

export const getBalance = () => async (dispatch) => {
    try {
        const res = await getBalanceApi();
        dispatch(setBalance(res.data));
    } catch (error) {
        console.error(error);
    }
}