import {Button, Caption, Card, Skeleton, Subheadline} from "@telegram-apps/telegram-ui";
import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import IconTon from "../../Components/icons/IconTon";
import {TonConnectButton} from "@tonconnect/ui-react";
import ModalConverter from "./ModalConverter";
import {useDispatch, useSelector} from "react-redux";
import {getBalance} from "../../slices/balance/thunk";
import {formatNumber} from "../../helpers/format_helper";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import converter from "../../assets/lottie/dotlottie/converter.lottie";
import loopmoney from "../../assets/lottie/dotlottie/loopmoney.lottie";
import IconLottyCoin from "../../Components/icons/IconLottyCoin";
import IconLottyStar from "../../Components/icons/IconLottyStar";
import ModalWithdraw from "./ModalWithdraw";

const WalletList = () => {
    const {balance, loaded} = useSelector(state => state.Balance)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        dispatch(getBalance())
    }, [])
    const currencies = [
        {name: "TON Coin", short_name: "TON", amount: balance?.ton_balance, icon: <IconTon className={"ton-currency-icon"} />, small_icon : <IconTon className={"transaction-icon"} />},
        {
            name: "Silver Stars",
            short_name: "STR",
            amount: balance?.star_balance,
            icon: <IconLottyStar style={{height: 30}}/>,
            small_icon : <IconLottyStar style={{height: 20, width: 20}}/>},
        {
            name: "Lotty Coin",
            short_name: "LTY",
            amount: balance?.coin_balance,
            icon: <IconLottyCoin style={{height: 30}}/>,
            small_icon : <IconLottyCoin style={{height: 20, width: 20}}/>
        },
    ]
    return (
        <>
            <div className={"balance-block d-flex flex-column gap-2"}>
                {currencies && currencies.map((el, i) => (
                        <Card key={i} className={"d-flex gap-2 flex-column p-3"}>
                                <div className={"currency d-flex justify-content-between w-100 align-items-center"}>
                                    <Skeleton visible={!loaded}>
                                        <div className={"d-flex gap-2 align-items-center"}>
                                        <div className={"currency-icon-div"}>
                                            {el.icon}
                                        </div>
                                        <div className={"d-flex flex-column currency-name"}>
                                            <Subheadline>
                                                {el.name}
                                            </Subheadline>
                                            <Caption className={"subtitle-color"}>
                                                {el.short_name}
                                            </Caption>
                                        </div>
                                    </div>
                                    </Skeleton>
                                    <Skeleton visible={!loaded}>
                                        <div className={"currency-amount d-flex gap-2 align-items-center"}>
                                            <Subheadline weight={3}>{formatNumber(el.amount)}</Subheadline>
                                            {el.small_icon}
                                        </div>
                                    </Skeleton>
                                </div>
                            <Skeleton visible={!loaded}>
                            {el.short_name == 'TON' &&
                                <div className={"d-flex gap-2 w-100 mt-2 flex-md-wrap"}>
                                    <TonConnectButton
                                        className={"ton-connect-wallet"}
                                    />
                                    <Button
                                        onClick={() => {
                                            navigate('/withdraw')
                                        }}
                                        before={
                                            '💸'
                                        }
                                        className={"w-50"} style={{minHeight: 40}} size={"s"} stretched>
                                        Withdraw
                                    </Button>
                                </div>
                            }
                            {el.short_name == 'STR' &&
                                <div className={"d-flex gap-2 w-100 mt-2 justify-content-center"}>
                                    <Button
                                        before={
                                        <DotLottieReact
                                            style={{
                                                height: 25,
                                                width: 45
                                            }}
                                            src={converter}
                                            loop
                                            autoplay
                                        />
                                    }
                                        onClick={() => {
                                            navigate('/converter')
                                        }}
                                        style={{minHeight: 40}}
                                        size={"s"} stretched
                                    >
                                        Converter
                                        </Button>
                                </div>

                            }
                            {el.short_name == 'LTY' &&
                                <div className={"d-flex gap-2 w-100 mt-2 text-center"}>
                                    <Caption weight={3}>
                                        You will be able to convert your Lotty coins
                                        to $LTY token later. <Link className={"tg-link"}>Learn more</Link>
                                    </Caption>
                                </div>
                            }
                            </Skeleton>
                        </Card>
                ))}
            </div>
        </>
    )
}
export default WalletList