import {APIClient} from "./api_helper";

const api = new APIClient()

export const authTgUser = (data) => api.create('/auth', data)

export const getRandomPrize = () => api.get('/games/roulette')

export const getTasksApi = () => api.get('/tasks')
export const getFriendsApi = (page) => api.get('/friends', {'page': page})
export const getTransactionsApi = (page) => api.get('/transactions', {'page': page})

export const checkTaskApi = (task) => api.get(`/tasks/${task.id}/check`)

export const getRafflesApi = (page, type, innerType, showTop = false) => api.get('/raffles',
    {
        page: page,
        'filter[type]' : type,
        'filter[innerType]' : innerType,
        'filter[showTop]' : showTop
    })

export const participateRaffleApi = (raffle, data) => api.create(`/raffles/${raffle.id}/participate`, data)
export const cancelParticipateRaffleApi = (raffle) => api.create(`/raffles/${raffle.id}/cancel-participation`)

export const getRaffleWinnersApi = (page, raffle) => api.get(`/raffles/${raffle.id}/winners`, {page: page})
export const getCountsApi = () => api.get(`/counters`)
export const getBalanceApi = () => api.get(`/balance`)
export const convertTokensApi = (data) => api.create('/balance/convert', data)

export const getTopUsersApi = () => api.get(`/users/top-100`)
export const createInvoiceLinkApi = (data) => api.create(`/payments/create-invoice`, data)