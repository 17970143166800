import {createSlice} from "@reduxjs/toolkit";

export const initialState = {
    currentTab: "lottos",
    showConfetti: false,
    showWelcomeModal: true,
    reward: false,
    counters : null,
    rewardUpdateKey: false,
    telegramStarsLoading : false,
    finalModalData: false
}

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
       setCurrentTabbarItem(state, action) {
           state.currentTab = action.payload
       },
        setShowConfetti(state, action) {
            state.showConfetti = action.payload
        },
        setWelcomeModal(state, action) {
            state.showWelcomeModal = action.payload
        },
        setReward(state, action) {
            state.reward = action.payload
            state.rewardUpdateKey = Math.random()
        },
        setCounters(state, action) {
            state.counters = action.payload
        },
        setTelegramStarsLoading(state, action) {
           state.telegramStarsLoading = action.payload
        },
        setFinalModalData(state, action) {
           state.finalModalData = action.payload
        }
    }
})

export const {
    setCurrentTabbarItem,
    setShowConfetti,
    setWelcomeModal,
    setReward,
    setCounters,
    setTelegramStarsLoading,
    setFinalModalData
} = appSlice.actions

export default appSlice.reducer