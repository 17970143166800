import {Button, FixedLayout, IconButton, Input, Title} from "@telegram-apps/telegram-ui";
import Select from "@mui/material/Select";
import {MenuItem} from "@mui/material";
import {CgArrowsExchangeAltV} from "react-icons/cg";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import IconLottyStar from "../../Components/icons/IconLottyStar";
import IconTon from "../../Components/icons/IconTon";
import IconLottyCoin from "../../Components/icons/IconLottyCoin";
import {Howl} from "howler";
import {convertTokensApi} from "../../helpers/real_api_helper";
import {getBalance} from "../../slices/balance/thunk";
import {getTransactions} from "../../slices/transactions/thunk";
import {setReward} from "../../slices/app/reducer";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import ReactDOM from "react-dom";
import ModalConfirm from "../Profile/ModalConfirm";

const Converter = () => {

    const dispatch = useDispatch()
    const {balance} = useSelector(state => state.Balance)
    const navigate = useNavigate()

    const currencies = [
        {name: "star", short_name: "str"},
        {name: "ton", short_name: "ton"},
        {name: "coin", short_name: "lty"}
    ]
    const defaultFromOptions = [
        { name: "STR", icon: <IconLottyStar className={"star-convert-icon"} />, value: 'str' },
        { name: "TON", icon: <IconTon className={"ton-convert-icon"} />, value: 'ton' },
    ];

    const defaultToOptions = [
        { name: "TON", icon: <IconTon className={"ton-convert-icon"} />, value: 'ton' },
        { name: "LTY", icon: <IconLottyCoin className={"star-convert-icon"} />, value: 'lty' },
    ];

    const starPrice = 0.00002;
    const coinPrice = 97.3;
    const [exchangeFrom, setExchangeFrom] = useState('str');
    const [exchangeTo, setExchangeTo] = useState('ton');
    const [exchangeToOptions, setExchangeToOptions] = useState(defaultToOptions);
    const [exchangeFromOptions, setExchangeFromOptions] = useState(defaultFromOptions);
    const [currentFrom, setCurrentFrom] = useState(0);
    const [currentTo, setCurrentTo] = useState(0);
    const [disabledReverse, setDisabledReverse] = useState(false);
    const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
    const [currentCourse, setCurrentCourse] = useState(starPrice);
    const [loading, setLoading] = useState(false)
    const [isValid, setIsValid] = useState(false)

    const playSound = () => {
        let sound;
        sound = new Howl({
            src: ['/audio/money.mp3']
        });
        sound.play();
    }
    const convert = () => {
        let from = currencies.find(el => el.short_name === exchangeFrom)
        let to = currencies.find(el => el.short_name === exchangeTo)
        let data = {
            from: from.name,
            to: to.name,
            amount: currentFrom
        }
        setLoading(true)
        setModalConfirmOpen(false)
        convertTokensApi(data)
            .then(res => {
                navigate('/wallet')
                dispatch(getBalance())
                dispatch(getTransactions())
                let reward = [
                    {
                        reward_type: from.name,
                        amount: currentFrom,
                        transaction_type: 'debit'
                    },
                    {
                        reward_type: to.name,
                        amount: currentTo,
                        transaction_type: 'credit'
                    }

                ]
                dispatch(setReward(reward))
                playSound()
            })
            .catch(err => {
                toast.warning(err?.data?.message)
            })
            .finally(() => {
                setLoading(false)
            })

    };

    const calculateCourse = (from, to) => {
        let course;
        if (from === 'str' && to === 'lty') course = coinPrice;
        else if (from === 'str' && to === 'ton') course = starPrice ;
        else if (from === 'ton' && to === 'str') course = 52500;
        else if (from === 'ton' && to === 'lty') course = 52500 * coinPrice;
        return course;
    };

    useEffect(() => {
        const backButtonHandler = () => {
            navigate(-1)
            window.Telegram.WebApp.BackButton.hide()
        }

        window.Telegram.WebApp.BackButton.show()
        window.Telegram.WebApp.onEvent('backButtonClicked', backButtonHandler);
        return () => {
            window.Telegram.WebApp.offEvent('backButtonClicked', backButtonHandler);
        };
    }, [navigate]);

    useEffect(() => {
        if (currentFrom >= 1)
            setIsValid(true)
        else
            setIsValid(false)
    }, [currentFrom])

    useEffect(() => {
        let course = calculateCourse(exchangeFrom, exchangeTo);
        if (course) setCurrentCourse(course);
    }, [exchangeFrom, exchangeTo]);

    useEffect(() => {
        if (exchangeFrom === 'str') {
            setExchangeToOptions(defaultToOptions.filter(option => option.value !== 'str'));
        } else if (exchangeFrom === 'ton') {
            setExchangeToOptions([
                { name: "STR", icon: <IconLottyStar className={"star-convert-icon"} />, value: 'str' },
                { name: "LTY", icon: <IconLottyStar className={"star-convert-icon"} />, value: 'lty' }
            ]);
        }

        setExchangeFromOptions(defaultFromOptions.filter(option => option.value !== exchangeTo));
    }, [exchangeFrom, exchangeTo]);

    useEffect(() => {
        let converted = Number(currentFrom * currentCourse)
        setCurrentTo(converted);
    }, [currentFrom, currentCourse]);

    useEffect(() => {
        setDisabledReverse(false);
        if (exchangeTo === 'lty') setDisabledReverse(true);
    }, [exchangeTo]);


    const handleExchangeTo = (e) => {
        setExchangeTo(e.target.value);
    };

    const handleReverse = () => {
        const newExchangeFrom = exchangeTo;
        const newExchangeTo = exchangeFrom;
        setExchangeFrom(newExchangeFrom);
        setExchangeTo(newExchangeTo);
    };

    const handleExchangeFrom = (e) => {
        setExchangeFrom(e.target.value);
    };

    const setMaxAmount = () => {
        let maxAmount
        switch(exchangeFrom) {
            case 'str':
                maxAmount = balance.star_balance
                break
            case 'ton':
                maxAmount = balance.ton_balance
                break
            default:
                maxAmount = balance.star_balance
        }
        setCurrentFrom(maxAmount)
    }

    const appRoot = document.getElementById('app-root');
    if (!appRoot) {
        console.error('AppRoot элемент не найден в DOM');
        return null;
    }

    const content = (
        <>
            <ModalConfirm
                isOpen={modalConfirmOpen}
                modalClose={() => setModalConfirmOpen(false)}
                onConfirm={convert}
            />
            <div
                style={{
                    position: 'fixed',
                    zIndex: 1
                }}
                className={"d-flex align-items-center flex-column p-3 w-100 min-vh-100 tg-secondary-bg"}>
                <div className={"d-flex mb-4"}>
                    <Title weight={1}>Converter</Title>
                </div>

                <div className={"converter d-flex w-100 flex-column gap-4 justify-content-center align-items-center"}>
                    <div className={"w-100 d-flex align-items-center gap-4 justify-content-center convert-input-div"}>
                        <Input
                            status={isValid ? 'default' : 'error'}
                            type={"number"}
                            inputMode="decimal"
                            onChange={(e) => setCurrentFrom(e.target.value)}
                            value={currentFrom}
                            className={"convert-input w-100"}
                            after={
                                <Button size={"s"} onClick={setMaxAmount} mode={"plain"}>Max</Button>
                            }
                            id="input-field"
                        />
                        <div className={"select-currency"}>
                            <Select id="exchange-from" value={exchangeFrom} onChange={handleExchangeFrom}>
                                {exchangeFromOptions.map((el, i) => (
                                    <MenuItem key={i} value={el.value}>
                                        <div className={"d-flex gap-1 align-items-center"}>
                                            {el.icon}
                                            <span className={"subtitle-color"}>{el.name}</span>
                                        </div>
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </div>

                    <div className={"convert-icon d-flex align-items-center"}>
                        <IconButton disabled={disabledReverse} onClick={handleReverse}>
                            <CgArrowsExchangeAltV size={26} />
                        </IconButton>
                    </div>

                    <div className={"w-100 d-flex align-items-center gap-4 justify-content-center convert-input-div"}>
                        <Input
                            disabled
                            status={"default"}
                            type={"number"}
                            value={currentTo}
                            className={"convert-input"}
                        />
                        <div className={"select-currency"}>
                            <Select id="exchange-to" value={exchangeTo} onChange={handleExchangeTo}>
                                {exchangeToOptions.map((el, i) => (
                                    <MenuItem key={i} value={el.value}>
                                        <div className={"d-flex gap-1 align-items-center"}>
                                            {el.icon}
                                            <span className={"subtitle-color"}>{el.name}</span>
                                        </div>
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <FixedLayout
                        className={"px-3 safe-padding-bottom"}
                        vertical="bottom">
                        <Button
                            disabled={!isValid}
                            loading={loading}
                            stretched
                            onClick={() => setModalConfirmOpen(true)}
                        >
                            Convert
                        </Button>
                    </FixedLayout>
                </div>
            </div>
        </>

    )
    return ReactDOM.createPortal(content, appRoot);
}

export default Converter