import {ModalHeader} from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import {ModalClose} from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose";
import {Icon28Close} from "@telegram-apps/telegram-ui/dist/icons/28/close";
import Lottie from "lottie-react";
import lottieRoulette from "../../assets/lottie/roulette.json";
import {Button, Headline, Modal, Title} from "@telegram-apps/telegram-ui";
import React from "react";
import Drawer from "./Drawer";

const ModalDailyWheel = (props) => {
    const {isOpen, modalClose, errorMessage} = props
    return (
        <Drawer
            isOpen={isOpen}
            onClose={modalClose}
        >
            <div className={"d-flex w-100 flex-column gap-3 p-3 justify-content-center align-items-center"}>
                <Title
                    level="1"
                    weight="2"
                >
                    No more attempts!
                </Title>
                <Headline
                    weight={3}
                >
                    {errorMessage}
                </Headline>
                <div className={"d-flex w-100 flex-column gap-3"}>
                    <Button>Invite friend</Button>
                </div>

            </div>
        </Drawer>
    )
}

export default ModalDailyWheel