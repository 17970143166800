import {createSlice} from "@reduxjs/toolkit";

export const initialState = {
    raffles: [],
    meta: null,
    selectedRaffle: null,
    loading: false,
    showSkeleton: false,
    loaded: false,
    selectedTab: 'active',
    winners: null,
    winnersMeta: null,
    selectedInnerType: 'all',
    topRaffles: [],
    topRaffleSkeleton: false
}

const appSlice = createSlice({
    name: "raffles",
    initialState,
    reducers: {
        setRaffles(state, action) {
            if (action.payload.append) {
                state.raffles = [...state.raffles, ...action.payload.data];
            } else {
                state.raffles = action.payload.data;
            }
            state.loaded = true
        },
        setWinners(state, action) {
            if (action.payload.append) {
                state.winners = [...state.winners, ...action.payload.data];
            } else {
                state.winners = action.payload.data;
            }
            state.loaded = true
        },
       setRaffleMeta(state, action) {
           state.meta = action.payload
       },
        setSelectedRaffle(state, action) {
           state.selectedRaffle = action.payload
        },
        setLoading(state, action) {
           state.loading = action.payload
        },
        setSelectedTab(state, action) {
           state.selectedTab = action.payload
        },
        setShowSkeleton(state, action) {
            state.showSkeleton = action.payload
        },
        setWinnersMeta(state, action) {
            state.winnersMeta = action.payload
        },
        setSelectedInnerType(state, action) {
            state.selectedInnerType = action.payload
        },
        setTopRaffles(state, action) {
            state.topRaffles = action.payload
            state.topRaffleSkeleton = true
        },
        setTopRaffleSkeleton(state, action) {
            state.topRaffleSkeleton = action.payload
        }
    }
})

export const {
    setRaffles,
    setRaffleMeta,
    setSelectedRaffle,
    setLoading,
    setShowSkeleton,
    setSelectedTab,
    setWinners,
    setWinnersMeta,
    setSelectedInnerType,
    setTopRaffles,
    setTopRaffleSkeleton
} = appSlice.actions

export default appSlice.reducer