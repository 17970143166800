import {authTgUser, createInvoiceLinkApi, getCountsApi} from "../../helpers/real_api_helper";
import {setCookie} from "../../helpers/cookie_helper";
import {setCounters, setTelegramStarsLoading} from "./reducer";
import {loginSuccess} from "../auth/reducer";
import {toast} from "react-toastify";

export const getCounters = () => async (dispatch) => {
    try {
        const res = await getCountsApi();
        dispatch(setCounters(res.data));
    } catch (error) {
        console.error(error);
    }
}

export const payWithTelegramStars = (amount, payload) => async (dispatch) =>{
    let data = {
        amount: amount,
        payload: payload

    }
    dispatch(setTelegramStarsLoading(true))
    createInvoiceLinkApi(data)
        .then(res => {
            window.Telegram.WebApp.openInvoice(res.data.link)
        })
        .catch(err => {
            toast.warning(err.data.message)
        })
        .finally(() => {
            dispatch(setTelegramStarsLoading(false))
        })
}

