export const subscribeTelegram = (id) => {
    window.Telegram.WebApp.openTelegramLink('https://t.me/'+id)
}

export const inviteFriends = (user) => {
    if (user) {
        const text = 'Welcome to Looty! Invite friends and loot TON'
        const url = `https://t.me/looty/start?startapp=${user.id}`
        window.Telegram.WebApp.openTelegramLink(`https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`)
    }
}