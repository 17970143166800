import {Avatar, Badge, Button, Caption, Card, Subheadline} from "@telegram-apps/telegram-ui";
import {GiAndroidMask, GiPerspectiveDiceSixFacesRandom} from "react-icons/gi";
import IconTon from "../../Components/icons/IconTon";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getTransactions} from "../../slices/transactions/thunk";
import IconLottyStar from "../../Components/icons/IconLottyStar";
import {formatNumber} from "../../helpers/format_helper";
import TransactionSkeleton from "./TransactionSkeleton";
import {getFriends} from "../../slices/friends/thunk";
import {BiTask} from "react-icons/bi";
import {GrTransaction} from "react-icons/gr";
import {TfiCup} from "react-icons/tfi";
import {PiHandWithdrawBold} from "react-icons/pi";
import {FaArrowUp, FaUserPlus} from "react-icons/fa";
import IconLottyCoin from "../../Components/icons/IconLottyCoin";
import {RiHandCoinFill} from "react-icons/ri";
import {MdKeyboardDoubleArrowUp} from "react-icons/md";

const TransactionsList = () => {
   const dispatch = useDispatch()
    const {transactions, loaded, meta} = useSelector(state => state.Transactions)
    const {counters} = useSelector(state => state.App)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        dispatch(getTransactions())
    }, []);

    const showMore = async () => {
        setLoading(true)
        dispatch(getTransactions(meta.current_page + 1, true))
            .finally(() => {
                setLoading(false)
            })
    }

    const getTransactionIcon = (type) => {
        switch(type) {
            case 'task_completion':
                return <BiTask />
            case 'convert':
                return <GrTransaction />
            case 'raffle_win':
                return <TfiCup />
            case 'withdraw_ton':
                return <PiHandWithdrawBold />
            case 'invite_friend':
                return <FaUserPlus />
            case 'roulette_win':
                return <GiPerspectiveDiceSixFacesRandom />
            case 'increase_chance':
                return <MdKeyboardDoubleArrowUp />
            case 'participation_payment':
                return <RiHandCoinFill />
            default:
                return <BiTask />
        }
    }

    const getConvertIcon = (token) => {
        switch (token) {
            case 'ton':
                return <IconTon
                    style={{
                        height: 15
                    }}
                />
            default:
                return <IconLottyStar
                    style={{
                        height: 15
                    }}
                />
        }
    }

    const getTransactionTitle = (transaction) => {
        switch(transaction.transaction_type) {
            case 'convert':
                return (
                    <div className={"d-flex align-items-center gap-2"}>
                        <span> Converted</span>
                        <div className={"d-flex align-items-center gap-1"}>
                            {getConvertIcon(transaction.payload.from.name)}
                            {formatNumber(transaction.payload.from.amount)}
                        </div>

                    </div>


                )
            default:
                return transaction.transaction_title
        }
    }

    return (
        <div className={"d-flex pt-4 flex-column"}>
            <Subheadline className={"mb-2 ms-3"}>
                My transactions
            </Subheadline>
            <Card
                className={"d-flex flex-column p-3"}
            >
                <div className={"transaction-table d-flex flex-column gap-3"}>
                    {loaded && !transactions?.length &&
                        <div className={"text-center"}>
                            <Subheadline>You don't have any transactions yet.</Subheadline>
                        </div>
                    }
                    {!loaded && Array.from({ length: counters?.count_transactions }, (_, index) => {
                        if (index < 4)
                            return <TransactionSkeleton key={index} />
                        return ''
                    })}
                    {transactions && transactions.map((el, i) => (
                        <div key={i} className={"transaction d-flex justify-content-between align-items-center"}>
                            <div className={"d-flex flex-row gap-3"}>
                                <Avatar>
                                    {getTransactionIcon(el.transaction_type)}
                                </Avatar>
                                <div className={"d-flex flex-column"}>
                                    <Subheadline>
                                        {getTransactionTitle(el)}
                                    </Subheadline>
                                    <Caption className={"subtitle-color"}>{el.created_at}</Caption>
                                </div>
                            </div>
                            <div className={"d-flex bonus-amount align-items-center gap-2"}>

                                <span>
                                    {el.amount_type == 'credit' ? '+' : '-'}
                                    {formatNumber(el.amount)}</span>
                                {el.token == 'star' && <IconLottyStar style={{height: 20, width: 20}}/>}
                                {el.token == 'ton' && <IconTon style={{height: 20, width: 20}} />}
                                {el.token == 'coin' && <IconLottyCoin style={{height: 20, width: 20}} />}
                            </div>
                        </div>
                    ))}
                </div>
                {meta?.last_page > meta?.current_page &&
                    <div className={"pagination d-flex justify-content-center pt-2"}>
                        <Button
                            loading={loading}
                            onClick={showMore}
                            mode={"plain"}
                        >Show more
                        </Button>
                    </div>}
            </Card>
        </div>
    )
}

export default TransactionsList