import {useDispatch, useSelector} from "react-redux";
import Drawer from "./Drawer";
import {setFinalModalData, setShowConfetti} from "../../slices/app/reducer";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import welcome from "../../assets/lottie/dotlottie/welcome.lottie";
import {Button, Subheadline, Title} from "@telegram-apps/telegram-ui";
import React from "react";

const ModalFinal = () => {
    const {finalModalData} = useSelector(state => state.App)
    const dispatch = useDispatch()
    const onClose = () => {
        dispatch(setFinalModalData(false))
        dispatch(setShowConfetti(false))
    }

    return (
        <Drawer
            isOpen={finalModalData}
            onClose={onClose}
        >
            <div className={"d-flex flex-column gap-2 justify-content-center align-items-center p-4 pt-0"}>
                <div className={"welcome-icon"}>
                    <DotLottieReact
                        style={{
                            width: 150,
                            height: 75
                        }}
                        src={welcome}
                        loop
                        autoplay
                    />
                </div>
                <div className={"d-block mb-2"}>
                    <Title weight={1}>
                        {finalModalData?.title}
                    </Title>
                </div>
                <div className={"d-block mb-2 text-center"}>
                    <Subheadline weight={3}>
                        {finalModalData?.description}
                    </Subheadline>
                </div>
                <div className={"d-block w-100"}>
                    <Button
                        stretched
                        onClick={onClose}
                    >
                        Got it
                    </Button>
                </div>

            </div>
        </Drawer>
    )
}

export default ModalFinal