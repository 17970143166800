import Drawer from "../Drawer";
import React, {useEffect, useRef, useState} from "react";
import {Button, Card, Headline, IconButton, LargeTitle, Slider, Subheadline, Title} from "@telegram-apps/telegram-ui";
import Lottie from "lottie-react";
import giftLottie from "../../../assets/lottie/gift.json";
import {participateRaffleApi} from "../../../helpers/real_api_helper";
import {getRaffles, getTopRaffles, participateToRaffle} from "../../../slices/raffles/thunk";
import {setSelectedInnerType, setSelectedTab} from "../../../slices/raffles/reducer";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import IconLottyCoin from "../../icons/IconLottyCoin";
import {getBalance} from "../../../slices/balance/thunk";
import {formatNumber} from "../../../helpers/format_helper";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import chuvak from "../../../assets/lottie/dotlottie/chuvak.lottie";
import {setFinalModalData, setShowConfetti} from "../../../slices/app/reducer";

const ModalSelectNumber = (props) => {
    const {isOpen, modalClose, selectedRaffle, paymentType, telegramInvoiceLink} = props
    const [raffleTicketNumber, setTicketNumber] = useState(false)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [currentSliderValue, setCurrentSliderValue] = useState(0)
    const [chance, setChance] = useState(1)
    const {balance} = useSelector(state => state.Balance)
    const [remainingBalance, setRemainingBalance] = useState(false)
    const intervalRef = useRef(null);
    const holdTimeout = useRef(null);


    useEffect(() => {
        if (balance) {
            setRemainingBalance(balance.coin_balance)
        }
    }, [balance]);



    useEffect(() => {
        let new_chance;
        let remaining
        if (currentSliderValue > 0) {
            remaining = balance.coin_balance - currentSliderValue
            new_chance = Number(1 + currentSliderValue * 0.0001 + Math.floor(currentSliderValue / 1000) * 0.0001).toFixed(2);
            setRemainingBalance(remaining)
            setChance(new_chance)
        }
    }, [currentSliderValue]);

    const participate = async () => {
        let data = {
            ticketNumber : raffleTicketNumber,
            lotty_coins: currentSliderValue,
            payment_type: paymentType,
            invoice_link: telegramInvoiceLink
        }
        setLoading(true)
        dispatch(participateToRaffle(data, selectedRaffle))
            .then(async res => {
                await modalClose()
                let finalModalData = {
                    title: "Congratulations",
                    description: `You are subscribed to Lotty #${selectedRaffle.id}. Once all the spots are filled, participants will be randomly selected. You will also receive a notification with the result and prize.`
                }
                await dispatch(setShowConfetti(true))
                await dispatch(setFinalModalData(finalModalData))
            })
            .catch(err => {
                toast.warning(err.data.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const selectRandomTicket = () => {
        const took = selectedRaffle?.took_numbers; // Список занятых номеров
        const range = [1, selectedRaffle?.max_participants]; // Диапазон доступных номеров

        // Генерация случайного числа в диапазоне
        const getRandomNumber = (min, max) => {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        };

        // Функция для выбора случайного номера, который не входит в список занятых номеров
        if (selectedRaffle?.max_participants !== selectedRaffle?.current_participants) {
            let randomTicket;
            do {
                randomTicket = getRandomNumber(range[0], range[1]);
            } while (took.includes(randomTicket));
            setTicketNumber(randomTicket);
        }


    };

    useEffect(() => {
        if (isOpen) {
            selectRandomTicket()
        }
    }, [isOpen]);

    const handleSliderChange = (value) => {
        setCurrentSliderValue(value)
        window.Telegram.WebApp.HapticFeedback.impactOccurred('soft')
    }

    const handleSelectNumber = (value) => {
        setTicketNumber(value)
    }

    useEffect(() => {
        if (raffleTicketNumber < selectedRaffle?.max_participants && raffleTicketNumber > 0) {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('soft')
        }
    }, [raffleTicketNumber])

    const checkIfNumberHasBeenTaken = () => {
        if (selectedRaffle?.took_numbers?.includes(raffleTicketNumber))
            return true
        return false;
    }

    const updateRaffleTicketNumber = (value) => {
        if (raffleTicketNumber > 0 && raffleTicketNumber <= selectedRaffle?.max_participants)
            setTicketNumber(value)
    }

    const startAction = (func) => {
        holdTimeout.current = setTimeout(() => {
            if (!intervalRef.current) {
                intervalRef.current = setInterval(() => {
                    func();
                }, 1); // Интервал в 1 мс
            }
        }, 500); // Задержка в 100 мс перед началом интервала
    };

    const stopAction = () => {
        clearTimeout(holdTimeout.current); // Очищаем задержку, если не успел запуститься
        if (intervalRef.current) {
            clearInterval(intervalRef.current); // Очищаем таймер
            intervalRef.current = null; // Сбрасываем ref
        }
    };

    const decrementTicketNumber = () => {
        setTicketNumber(prev => {
            // Проверка, чтобы не ушло в минус
            if (prev > 1) {
                return prev - 1;
            }
            return prev; // Возвращаем предыдущее значение, если оно 1
        });
    };

    const incrementTicketNumber = () => {
        setTicketNumber(prev => {
            // Проверка, чтобы не превышать max_participants
            if (prev < selectedRaffle.max_participants) {
                return prev + 1;
            }
            return prev; // Возвращаем предыдущее значение, если достигнут лимит
        });
    };


    return (
        <Drawer
            isOpen={isOpen}
            onClose={modalClose}
        >
            <div className={"d-flex p-3 flex-column gap-3 no-select"}>
                <div className={"text-center"}>
                    <LargeTitle>Lotty #{selectedRaffle?.id}</LargeTitle>
                </div>
                <div className={"d-flex flex-row text-center mb-1 justify-content-center align-items-center gap-1"}>
                    <DotLottieReact
                        src={chuvak}
                        loop
                        autoplay
                        style={{
                            height: 20,
                            width: 20,
                        }}
                    />
                    <span className={"ton_num"}>
                    {formatNumber(selectedRaffle?.current_participants)} / {formatNumber(selectedRaffle?.max_participants)}
                    </span>
                </div>
                <Card className={"py-4 px-3"}>
                    <div className={"d-flex flex-column gap-1 w-100"}>
                        {/*<Subheadline className={"d-flex gap-1 align-items-center"}>*/}
                        {/*    Increase your chance with Lotty Coins*/}
                        {/*    <IconLottyCoin*/}
                        {/*        style={{*/}
                        {/*            height: 15,*/}
                        {/*            width: 15*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*</Subheadline>*/}
                        <div className={"text-center"}>
                            <div className={"mb-4"}>
                                <Title>Choose your lucky number</Title>
                            </div>

                            <div className={"d-flex gap-2 align-items-center justify-content-center position-relative ticket-btns"}>
                                <IconButton
                                    onClick={decrementTicketNumber} // Обычный клик с проверкой уменьшения
                                    className={"position-absolute start-0"}
                                    size={"l"}
                                    disabled={raffleTicketNumber === 1}
                                    mode={"gray"}
                                    onMouseDown={() => startAction(decrementTicketNumber)} // Запуск при зажатии
                                    onMouseUp={stopAction} // Остановка при отпускании
                                    onMouseLeave={stopAction} // Остановка при уходе курсора
                                    onTouchStart={() => startAction(decrementTicketNumber)} // Запуск на мобильных устройствах
                                    onTouchEnd={stopAction} // Остановка на мобильных
                                >
                                    -
                                </IconButton>

                                <IconButton
                                    onClick={incrementTicketNumber} // Обычный клик с проверкой увеличения
                                    className={"position-absolute end-0"} // Измените позицию по необходимости
                                    size={"l"}
                                    disabled={raffleTicketNumber === selectedRaffle?.max_participants}
                                    mode={"gray"}
                                    onMouseDown={() => startAction(incrementTicketNumber)} // Запуск при зажатии
                                    onMouseUp={stopAction} // Остановка при отпускании
                                    onMouseLeave={stopAction} // Остановка при уходе курсора
                                    onTouchStart={() => startAction(incrementTicketNumber)} // Запуск на мобильных устройствах
                                    onTouchEnd={stopAction} // Остановка на мобильных
                                >
                                    +
                                </IconButton>

                                <LargeTitle

                                    className={`${checkIfNumberHasBeenTaken() ? 'text-red' : 'text-green'}`}
                                    style={{
                                        fontFamily: "Chakra Petch",
                                        fontSize: 68,
                                        lineHeight: "inherit!important"
                                    }}
                                    weight={3}>
                                    {raffleTicketNumber}
                                </LargeTitle>

                            </div>

                        </div>
                        <div className={"position-relative mt-4"}>
                            <div className={"d-block lotty-ticket-info"}>
                                <span className={"position-absolute start-0"}>1</span>
                                <span className={"position-absolute end-0"}>{formatNumber(selectedRaffle.max_participants)}</span>
                            </div>

                            <div className={"header-bg wrapper-slider mt-4"}>
                                <Slider
                                    className={"slider-no-bg ticket-number-slider"}
                                    onChange={(value) => handleSelectNumber(value)}
                                    value={raffleTicketNumber}
                                    max={selectedRaffle.max_participants}
                                    min={1}
                                />
                            </div>
                        </div>

                    </div>
                </Card>
                <Card className={"p-3"}>
                    <div className={"d-flex flex-column gap-1 w-100"}>
                        <Subheadline className={"d-flex gap-1 align-items-center"}>
                            Increase your chance with Lotty Coins
                            <IconLottyCoin
                                style={{
                                    height: 15,
                                    width: 15
                                }}
                            />
                        </Subheadline>
                        <div className={"d-flex mb-2 align-items-center justify-content-between"}>

                            <Subheadline className={`d-flex gap-1 align-items-center lotty-text`}>
                                Balance:
                                <span
                                    className={`${balance.coin_balance > 0 ? 'text-green' : 'text-danger'}`}
                                >
                                    {formatNumber(remainingBalance)}
                                </span>

                                <IconLottyCoin
                                style={{
                                    height: 20,
                                    width: 20
                                }}
                            />
                            </Subheadline>
                            <Subheadline
                                className={"lotty-text"}
                            >
                                Chance: {chance}x
                            </Subheadline>
                        </div>

                        <div className={"header-bg wrapper-slider mt-4"}>
                            <Slider
                                disabled={balance.coin_balance == 0}
                                className={"lotty-slider"}
                                onChange={(value) => handleSliderChange(value)}
                                value={currentSliderValue}
                                max={Number(balance.coin_balance)}
                                min={0}
                            />
                        </div>
                    </div>
                </Card>
                <div className={"d-block w-100"}>
                    <Button
                        onClick={participate}
                        loading={loading}
                        size={"l"}
                        stretched
                    >
                        Continue
                    </Button>
                </div>
            </div>

        </Drawer>
    )
}

export default ModalSelectNumber