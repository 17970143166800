import {createSlice} from "@reduxjs/toolkit";

export const initialState = {
    tasks: [],
    showSkeleton: false
}

const appSlice = createSlice({
    name: "tasks",
    initialState,
    reducers: {
       setTasks(state, action) {
           state.tasks = action.payload
       },
        setShowSkeleton(state, action) {
            state.showSkeleton = action.payload
        }
    }
})

export const {
    setTasks,
    setShowSkeleton
} = appSlice.actions

export default appSlice.reducer