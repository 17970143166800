import Footer from "./Footer";
import React, {useEffect} from 'react'
import 'react-toastify/dist/ReactToastify.css';
import {Flip, ToastContainer} from "react-toastify";
import Confetti from "react-confetti-boom";
import {useDispatch, useSelector} from "react-redux";
import ModalWelcome from "../Components/custom/ModalWelcome";
import ModalWelcomeTest from "../Components/custom/ModalWelcomeTest";
import RewardNumber from "../Components/custom/RewardNumber";
import {useLocation} from "react-router-dom";
import {setCurrentTabbarItem} from "../slices/app/reducer";
import {getCounters} from "../slices/app/thunk";
import {getBalance} from "../slices/balance/thunk";
import ModalFinal from "../Components/custom/ModalFinal";
const Layout = (props) => {
    const {showConfetti, currentTab} = useSelector(state => state.App)

    const dispatch = useDispatch()
    const location = useLocation()
    const tabs = [
        {id: 'rewards', text: 'Rewards', path: "/rewards"},
        {id: 'lottys', text: 'Lottos', path: "/lottys"},
        {id: 'friends', text: 'Friends', path: "/friends"},
        {id: 'wallet', text: 'Wallet', path: "/wallet"}
    ]

    useEffect(() => {
        let pathname = location.pathname
        let currentTabLocal = tabs.find(el => el.path == pathname)
        if (currentTabLocal && currentTab !== currentTabLocal.id) {
            dispatch(getCounters())
            dispatch(setCurrentTabbarItem(currentTabLocal?.id))
        }
    }, [tabs, currentTab])

    useEffect(() => {
        dispatch(getCounters())
        dispatch(getBalance())
    }, []);


    return (
        <React.Fragment>
            {showConfetti && <div className={"confetti"}>
                <Confetti particleCount={500} mode={"boom"} />
            </div>}
            <ModalWelcome />
            <ToastContainer
                position={"bottom-center"}
                theme={'dark'}
                autoClose={2000}
                hideProgressBar={true}
                limit={2}
            />
            <ModalFinal />
            <div className={"content"}>
                <RewardNumber />
                <div className={"content-scroll tg-secondary-bg"}>
                    <div className={"tg-secondary-bg"}>
                        <div className={"min-page-content-height"}>
                            {props.children}
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </React.Fragment>

    )
}

export default Layout