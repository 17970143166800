import IconTon from "../Components/icons/IconTon";
import IconLottyStar from "../Components/icons/IconLottyStar";
import {formatNumber} from "./format_helper";
import React from "react";
import MemoDotLottie from "../Components/custom/MemoDotLottie";
import first from "../assets/lottie/dotlottie/1st.lottie";
import second from "../assets/lottie/dotlottie/2nd.lottie";
import third from "../assets/lottie/dotlottie/3rd.lottie";
import dollar from "../assets/lottie/dotlottie/dollar.lottie";
import IconLottyCoin from "../Components/icons/IconLottyCoin";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import star from "../assets/lottie/dotlottie/star.lottie";
import crown from "../assets/lottie/dotlottie/crown.lottie";
import icon_new from "../assets/lottie/dotlottie/new.lottie";
import icon_soon from "../assets/lottie/dotlottie/soon.lottie";

export const getPrize = (prize, amount) => {
    let icon
    switch(prize.type){
        case 'ton':
            icon = <IconTon className="ton" />
            break
        case 'star':
            icon = <IconLottyStar style={{
                height: 15
            }} />
            break
        case 'coin':
            icon = <IconLottyCoin style={{
                height: 15
            }} />
            break
        default:
            return null
    }
    return (
        <div className={"d-flex align-items-center gap-1"}>
             <span>
                {formatNumber(amount)}
            </span>
            {icon}
        </div>
    )
}

const isPlaceInRange = (place, range) => {
    const [start, end] = range.split('-').map(Number);
    return place >= start && place <= end;
};

export const getMedal = (place, prizes, el) => {
    for (let i = 0; i < prizes.length; i++) {
        if (isPlaceInRange(place, prizes[i].range)) {
            if (i === 0) {
                return (
                    <MemoDotLottie
                        src={first}
                        loop
                        autoplay
                        style={{
                            height: 24,
                            width: 33
                        }}
                    />
                );
            } else if (i === 1) {
                return (
                    <MemoDotLottie
                        src={second}
                        loop
                        autoplay
                        style={{
                            height: 24,
                            width: 33
                        }}
                    />
                );
            } else if (i === 2) {
                return (
                    <MemoDotLottie
                        src={third}
                        loop
                        autoplay
                        style={{
                            height: 24,
                            width: 33
                        }}
                    />
                );
            }
            else {
                return getLottieIcon(el.prize.type)
            }
        }
    }
    return null;
};

export const getLottieIcon = (type) => {
    switch (type) {
        case 'ton':
            return (
                <DotLottieReact
                    src={dollar}
                    loop
                    autoplay
                    style={{
                        height: 24,
                        width: 33,
                    }}
                />
            );
        case 'star':
            return (
                <DotLottieReact
                    src={star}
                    loop
                    autoplay
                    style={{
                        height: 24,
                        width: 33,
                    }}
                />
            );
        case 'coin':
            return (
                <DotLottieReact
                    src={crown}
                    loop
                    autoplay
                    style={{
                        height: 24,
                        width: 33,
                    }}
                />
            );
        default:
            return null;
    }
};

export const checkIsNew = (current) => {
    let percentParticipants = current.current_participants * 100 / current.max_participants
    if (percentParticipants <= 50)
        return (
            <MemoDotLottie
                src={icon_new}
                loop
                autoplay
                style={{
                    height: 24,
                    width: 33
                }}
            />
        )
    else if (percentParticipants > 70)
        return (
            <MemoDotLottie
                src={icon_soon}
                loop
                autoplay
                style={{
                    height: 24,
                    width: 33
                }}
            />
        )
}