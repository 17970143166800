import React, {useEffect, useState} from "react";
import ModalRaffleParticipated from "./Raffle/ModalRaffleParticipated";
import Lottie from "lottie-react";
import starsLottie from "../../assets/lottie/stars.json";
import {Button, Caption, Headline, Subheadline, TabsList, Title} from "@telegram-apps/telegram-ui";
import {TabsItem} from "@telegram-apps/telegram-ui/dist/components/Navigation/TabsList/components/TabsItem/TabsItem";
import LotteryCard from "./LotteryCard";
import {useDispatch, useSelector} from "react-redux";
import {getRaffles} from "../../slices/raffles/thunk";
import FinishedLotteryCard from "./FinishedLotteryCard";
import ModalRaffleFinished from "./Raffle/ModalRaffleFinished";
import ModalRaffle from "./Raffle/ModalRaffle";
import {setSelectedRaffle, setSelectedTab, setShowSkeleton} from "../../slices/raffles/reducer";
import ModalRaffleWinner from "./Raffle/ModalRaffleWinner";
import TopRaffles from "../../pages/Home/TopRaffles";
import first from "../../assets/lottie/dotlottie/fire.lottie";
import third from "../../assets/lottie/dotlottie/brilliant.lottie";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import RaffleFilter from "../../pages/Home/RaffleFilter";
import HorizontalLotteryCard from "./Raffle/HorizontalLotteryCard";
import RaffleSkeleton from "./Raffle/RaffleSkeleton";
import HorizontalFinishedLotteryCard from "./Raffle/HorizontalFinishedLotteryCard";
import HorizontalCardSkeleton from "./Raffle/HorizontalCardSkeleton";

const RaffleList = (props) => {

    const dispatch = useDispatch()
    const {raffles, meta, loading, selectedRaffle, showSkeleton, loaded, selectedTab, selectedInnerType} = useSelector(state => state.Raffles)

    useEffect(() => {
        dispatch(setShowSkeleton(true))
        dispatch(getRaffles(1, selectedTab, selectedInnerType))
    }, [])

    const showMore = () => {
        dispatch(getRaffles(meta.current_page + 1, selectedTab ,selectedInnerType,true))
    }

    const onModalClose = () => {
        dispatch(setSelectedRaffle(false))
    }

    const tabs = [
        {name: "Available", id: 'active'},
        {name: "Drawn", id: 'finished'},
    ]

    const changeTab = (el) => {
        if (selectedTab !== el) {
            dispatch(setSelectedTab(el))
            dispatch(setShowSkeleton(true))
            dispatch(getRaffles(1, el))
        }
    }

    const getModalComponent = () => {
        if (selectedRaffle?.is_closed && selectedRaffle?.is_participating) {
            return (
                <ModalRaffleWinner
                    selectedRaffle={selectedRaffle}
                    modalClose={onModalClose}
                    isOpen={selectedRaffle}
                />
            );
        }
        else if (selectedRaffle?.is_closed && !selectedRaffle?.is_participating) {
            return (
                <ModalRaffleFinished
                    selectedRaffle={selectedRaffle}
                    modalClose={onModalClose}
                    isOpen={selectedRaffle}
                />
            );
        }

        else if (!selectedRaffle?.is_closed && selectedRaffle?.is_participating) {
            return (
                <ModalRaffleParticipated
                    selectedRaffle={selectedRaffle}
                    modalClose={onModalClose}
                    isOpen={selectedRaffle}
                />
            );
        }

        return (
            <ModalRaffle
                selectedRaffle={selectedRaffle}
                modalClose={onModalClose}
                isOpen={selectedRaffle}
            />
        );
    };

    return (
        <>
            {getModalComponent()}
            <div className={"tabs px-5 py-3"}>
                <TabsList>
                    {tabs && tabs.map((el, i) => (
                        <TabsItem
                            key={i}
                            onClick={() => changeTab(el.id)}
                            selected={selectedTab === el.id}
                        >
                            <div className={"d-flex justify-content-center align-items-center gap-2"}>
                                <DotLottieReact
                                    src={el.name === 'Available' ? first : third}
                                    loop
                                    autoplay
                                    style={{
                                        width: 20,
                                        height: 20
                                    }}
                                />
                                {el.name}

                            </div>
                        </TabsItem>
                    ))}
                </TabsList>
            </div>
            <TopRaffles />
            <RaffleFilter/>
            <div className={"d-flex gap-2 flex-column p-2"}>
                {!raffles.length &&
                    <div className={"text-center"}>
                        <Subheadline className={"subtitle-color"}>There is no Lotties here</Subheadline>
                    </div>

                }
                {raffles && raffles.map((el, i) => (
                    el.is_closed ?
                        <HorizontalFinishedLotteryCard
                            key={i}
                            current={el}
                        />
                        :
                        <HorizontalLotteryCard
                            key={i}
                            current={el}
                        />
                ))}
                {meta?.last_page > meta?.current_page &&
                    <div className={"pagination d-flex justify-content-center pt-2"}>
                        <Button
                            loading={loading}
                            onClick={showMore}
                            mode={"plain"}
                        >Show more
                        </Button>
                    </div>
                }
            </div>
        </>

    )
}

export default RaffleList