import ReactDOM from "react-dom";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Avatar, Badge, Button, Caption, FixedLayout, Headline, Section, Subheadline, Text} from "@telegram-apps/telegram-ui";
import IconTon from "../../Components/icons/IconTon";
import {formatNumber} from "../../helpers/format_helper";
import {useSelector} from "react-redux";

const Top100 = () => {
    const navigate = useNavigate()
    const {topUsers, currentUser} = useSelector(state => state.Users)
    const {user} = useSelector(state => state.Auth)
    useEffect(() => {
        const backButtonHandler = () => {
            navigate(-1)
            window.Telegram.WebApp.BackButton.hide()
        }

        window.Telegram.WebApp.BackButton.show()
        window.Telegram.WebApp.onEvent('backButtonClicked', backButtonHandler);
        return () => {
            window.Telegram.WebApp.offEvent('backButtonClicked', backButtonHandler);
        };
    }, [navigate]);


    const appRoot = document.getElementById('app-root');
    if (!appRoot) {
        console.error('AppRoot элемент не найден в DOM');
        return null;
    }

    const getCurrentUserRank = () => {
        let isInTop100 = topUsers.find(el => el.username === user.username)
        if (isInTop100)
            return isInTop100.rank
        return currentUser.rank != null ? formatNumber(currentUser.rank) : '-'
    }

    const content = (
        <div
            style={{
                position: 'fixed',
                zIndex: 1
            }}
            className={"d-flex flex-column w-100"}>
            <div className={"tg-secondary-bg top-users min-vh-100"}>
                <Headline weight={1} className={"section-separator px-3 py-2"}>Top 100 Winners</Headline>
                <div>
                    {topUsers && topUsers.map((el, i) => (
                    <div key={i} className={"top-user section-separator d-flex justify-content-between py-1 px-3"}>
                        <div className={"d-flex gap-2"}>
                            <div className={"user-avatar"}>
                                <Avatar
                                    size={50}
                                    src={`https://unavatar.io/telegram/${el.username}`}
                                />
                            </div>
                            <div className={"user-name d-flex flex-column"}>
                                <Text weight={2}>@{el.username}
                                    {el.username == user.username &&  <Badge type={"number"}>
                                        You
                                    </Badge>}
                                   </Text>
                                <div className={"d-flex align-items-center gap-1"}>
                                    <IconTon
                                        style={{
                                            height: 15,
                                            width: 15
                                        }}
                                    />
                                    <Caption className={"subtitle-color"}>
                                        {formatNumber(el.amount)}
                                    </Caption>

                                </div>
                            </div>
                        </div>
                        <div className={"user-rank d-flex align-items-center"}>
                            <Text
                                className={"subtitle-color"}
                            >{el.rank}</Text>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
            <FixedLayout
                className={"main-bg section-separator-top"}
                vertical="bottom">
                <div className={"top-user d-flex justify-content-between py-1 px-3"}>
                    <div className={"d-flex gap-2 align-items-center"}>
                        <div className={"user-avatar"}>
                            <Avatar
                                size={50}
                                src={`https://unavatar.io/telegram/${user.username}`}
                            />
                        </div>
                        <div className={"user-name d-flex flex-column"}>
                            <Text weight={2}>@{user.username} <Badge type={"number"}>
                                You
                            </Badge></Text>
                            <div className={"d-flex align-items-center gap-1"}>
                                <IconTon
                                    style={{
                                        height: 15,
                                        width: 15
                                    }}
                                />
                                <Caption className={"subtitle-color"}>
                                    {formatNumber(currentUser?.amount)}
                                </Caption>

                            </div>
                        </div>
                    </div>
                    <div className={"user-rank d-flex align-items-center"}>
                        <Text
                            className={"subtitle-color"}
                        >{getCurrentUserRank()}</Text>
                    </div>
                </div>

            </FixedLayout>
        </div>
    )
    return ReactDOM.createPortal(content, appRoot);
}

export default Top100