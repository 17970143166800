import { combineReducers } from "redux";
import AuthReducer from "./auth/reducer"
import AppReducer from "./app/reducer"
import TaskReducer from "./tasks/reducer"
import FriendReducer from "./friends/reducer"
import RaffleReducer from "./raffles/reducer"
import BalanceReducer from "./balance/reducer"
import TransactionReducer from "./transactions/reducer"
import UsersReducer from "./users/reducer"

const rootReducer = combineReducers({
    Auth: AuthReducer,
    App: AppReducer,
    Task: TaskReducer,
    Friends: FriendReducer,
    Raffles: RaffleReducer,
    Balance: BalanceReducer,
    Transactions: TransactionReducer,
    Users: UsersReducer
})

export default rootReducer