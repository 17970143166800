import Lottie from "lottie-react";
import friendsLottie from "../../assets/lottie/dotlottie/friends.lottie";
import {Headline, Title} from "@telegram-apps/telegram-ui";
import React from "react";
import InviteFriend from "./InviteFriend";
import FriendsList from "./FriendsList";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";

const Friends = () => {
    return (
        <div className={"p-2"}>
            <div
                className={"d-flex justify-content-center gap-2 flex-column align-items-center text-center mb-3"}>
                <DotLottieReact
                    style={{
                        width: 150,
                        height: 80
                    }}
                    src={friendsLottie}
                    loop
                    autoplay
                />
                <Title
                    weight={3}
                >
                    Invite friends
                </Title>
                <Headline>
                    You and your friend will receive bonuses
                    for invitation
                </Headline>
            </div>
            <InviteFriend />
            <FriendsList />
        </div>
    )
}

export default Friends