import IconTon from "../../Components/icons/IconTon";
import {Button, Caption, FixedLayout, Input, Title} from "@telegram-apps/telegram-ui";
import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import ModalConfirm from "../Profile/ModalConfirm";
import {useTonAddress, useTonConnectModal} from "@tonconnect/ui-react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const Withdraw = () => {
    const [modalConfirmOpen, setModalConfirmOpen] = useState(false)
    const [currentAmount, setCurrentAmount] = useState(false)
    const {balance} =  useSelector(state => state.Balance)
    const [isValid, setIsValid] = useState(false)
    const userFriendlyAddress = useTonAddress();
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const setMaxAmount = () => {
        setCurrentAmount(balance.ton_balance)
    }
    const withdraw = () => {

    }

    useEffect(() => {
        const backButtonHandler = () => {
            navigate('/wallet')
            window.Telegram.WebApp.BackButton.hide()
        }

        window.Telegram.WebApp.BackButton.show()
        window.Telegram.WebApp.onEvent('backButtonClicked', backButtonHandler);
        return () => {
            window.Telegram.WebApp.offEvent('backButtonClicked', backButtonHandler);
        };
    }, [navigate]);

    useEffect(() => {
        if (currentAmount >= 1)
            setIsValid(true)
        else
            setIsValid(false)
    }, [currentAmount]);

    const { state, open, close } = useTonConnectModal();

    const setAmount = (amount) => {
        setCurrentAmount(amount)
    }

    const appRoot = document.getElementById('app-root');
    if (!appRoot) {
        console.error('AppRoot элемент не найден в DOM');
        return null;
    }
    const content = (
        <>
            <ModalConfirm
                isOpen={modalConfirmOpen}
                modalClose={() => setModalConfirmOpen(false)}
                onConfirm={withdraw}
            />
            <div
                style={{
                    position:'fixed',
                    zIndex: 1
                }}
                className={"d-flex align-items-center flex-column p-3 min-vh-100 tg-secondary-bg w-100"}
            >
                <div className={"d-flex mb-4 gap-1 flex-column"}>
                    <div className={"d-flex gap-1 text-center justify-content-center"}>
                        <IconTon style={{height: 30}} />
                        <Title weight={1}>Withdraw Ton</Title>
                    </div>
                    <Caption className={"text-gray"}>
                        {userFriendlyAddress ? 'Enter the amount in TON and click Withdraw funds.' : 'To withdraw funds, please connect your TON wallet first'}

                    </Caption>
                </div>
                {userFriendlyAddress ?
                    <div className={"converter d-flex w-100 flex-column gap-4 justify-content-center align-items-center"}>
                        <div className={"w-100 d-flex  flex-column convert-input-div"}>
                            <Input
                                status={isValid ? 'default' : 'error'}
                                type={"number"}
                                inputMode="decimal"
                                onChange={(e) => setAmount(e.target.value)}
                                value={currentAmount}
                                className={"convert-input w-100"}
                                after={
                                    <Button size={"s"} onClick={setMaxAmount} mode={"plain"}>Max</Button>
                                }
                                id="input-field"
                            />
                            <Caption className={"ms-2 pt-2 subtitle-color"}>
                                Min: 1
                            </Caption>
                        </div>

                        <div className={"w-100 d-flex gap-2 convert-input-div flex-column"}>
                            <Caption className={"ms-2 pt-2 subtitle-color"}>
                                Connected wallet
                            </Caption>
                            <Input
                                disabled
                                status={"default"}
                                type={"text"}
                                value={userFriendlyAddress}
                            />
                        </div>
                        <FixedLayout
                            className={"px-3 safe-padding-bottom"}
                            vertical="bottom">
                            <Button
                                disabled={!isValid}
                                loading={loading}
                                stretched
                                onClick={() => setModalConfirmOpen(true)}
                            >
                                Withdraw funds
                            </Button>
                        </FixedLayout>
                    </div>
                    :
                    <FixedLayout
                        className={"px-3 safe-padding-bottom"}
                        vertical="bottom">
                        <Button
                            stretched
                            onClick={open}
                            before={
                                <IconTon
                                    style={{
                                        width: 20,
                                        height: 20
                                    }}
                                />
                            }
                        >
                            Connect wallet
                        </Button>
                    </FixedLayout>

                }

            </div>
        </>

    )

    return ReactDOM.createPortal(content, appRoot);
}

export default Withdraw