import {Button, Caption, Card, Headline, Subheadline} from "@telegram-apps/telegram-ui";
import React, {useEffect, useState} from "react";
import IconTon from "../../icons/IconTon";
import IconLottyStar from "../../icons/IconLottyStar";
import giftLottie from "../../../assets/lottie/gift.json";
import Lottie from "lottie-react";
import PrizeTable from "../../../pages/Home/PrizeTable";
import Drawer from "../Drawer";
import {useDispatch, useSelector} from "react-redux";
import {emojiConverter, formatNumber} from "../../../helpers/format_helper";
import {inviteFriends, subscribeTelegram} from "../../../helpers/telegram_app_helper";
import {checkTaskApi, createInvoiceLinkApi} from "../../../helpers/real_api_helper";
import {toast} from "react-toastify";
import ModalSelectNumber from "./ModalSelectNumber";
import Prizes from "./Parts/Prizes";
import IconTelegramStar from "../../icons/IconTelegramStar";
import chuvak from "../../../assets/lottie/dotlottie/chuvak.lottie";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import ModalConfirm from "../../../pages/Profile/ModalConfirm";
import {getBalance} from "../../../slices/balance/thunk";
import {payWithTelegramStars} from "../../../slices/app/thunk";

const ModalRaffle = (props) => {
    const {user} = useSelector(state => state.Auth)
    const {balance} = useSelector(state => state.Balance)
    const {telegramStarsLoading} = useSelector(state => state.App)
    const {isOpen, modalClose, selectedRaffle, selectedTab} = props
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [loadingConfirm, setLoadingConfirm] = useState(false)
    const [showSelectNumber, setShowSelectNumber] = useState(false)
    const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
    const [useSilverStars, setUseSilverStars] = useState(false)
    const [paymentType, setPaymentType] = useState('task')
    const [telegramInvoiceLink, setTelegramInvoiceLink] = useState(false)

    useEffect(() => {
        if (isOpen)
            dispatch(getBalance())
    }, [isOpen])

    const checkCompletion =  () => {
        setLoading(true)
        checkTaskApi(selectedRaffle?.task)
            .then(res => {
                setPaymentType('task')
                setShowSelectNumber(true)
            })
            .catch(err => {
                toast.warning(err.data.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const closeSelectNumber = () => {
        setUseSilverStars(false)
        setShowSelectNumber(false)
        setPaymentType('task')
        setTelegramInvoiceLink(false)
        modalClose()
    }

    const payWithSilverStars = () => {
        if (balance?.star_balance >= 100) {
            setShowSelectNumber(true)
            setUseSilverStars(true)
            setPaymentType('silver_stars')
        }
        else {
            toast.warning('Insufficient funds')
        }
        setModalConfirmOpen(false)
    }

    const sendTelegramInvoice = (amount) => {
        let payload = JSON.stringify({
            id: selectedRaffle.id,
            user_id: user.id
        })
        dispatch(payWithTelegramStars(amount, payload))
    }

    const payWithYellowStars = (url) => {
        setShowSelectNumber(true)
        setTelegramInvoiceLink(url)
        setPaymentType('telegram_stars')
    }

    useEffect(() => {
        const handleEvent = (res) => {
            if (res.status === 'paid') {
                payWithYellowStars(res.url)
            }
        };

        window.Telegram.WebApp.onEvent('invoiceClosed', handleEvent);

        // Cleanup the event listener on unmount
        return () => {
            window.Telegram.WebApp.offEvent('invoiceClosed', handleEvent);
        };
    }, []);

    return (
        <Drawer
           isOpen={isOpen}
           onClose={modalClose}
        >
            <ModalSelectNumber
                selectedRaffle ={selectedRaffle}
                isOpen={showSelectNumber}
                paymentType={paymentType}
                telegramInvoiceLink={telegramInvoiceLink}
                modalClose={closeSelectNumber}
            />
            <ModalConfirm
                isOpen={modalConfirmOpen}
                modalClose={() => setModalConfirmOpen(false)}
                onConfirm={() => payWithSilverStars()}
            />
            <div className={"d-flex w-100 flex-column gap-3 p-3 justify-content-center align-items-center"}>
                <div className={"task-icon"}>
                    <Lottie
                        style={{
                            width: 160,
                            height: 80
                        }}
                        animationData={giftLottie} loop={false}
                    />
                </div>
                <div className={"d-flex flex-row gap-2 align-items-center"}>
                    <Prizes prizes={selectedRaffle?.prizes} />
                </div>
                <div className={"d-flex flex-column text-center align-items-center "}>
                    <div className={"d-flex gap-1 flex-column align-items-center"}>
                        <Subheadline weight={2}>
                            Participants
                        </Subheadline>
                        <div className={"d-flex gap-1"}>
                            <DotLottieReact
                                src={chuvak}
                                loop
                                autoplay
                                style={{
                                    height: 20,
                                    width: 20,
                                }}
                            />
                            <span
                                className={"ton_num"}
                            >
                         {formatNumber(selectedRaffle?.current_participants)} / {formatNumber(selectedRaffle?.max_participants)}
                        </span>
                        </div>
                    </div>
                    <Caption className={"subtitle-color mt-1"}>
                        The raffle will start once all participants have joined.
                    </Caption>
                </div>
                <Headline
                    className={"text-center"}
                    level="1"
                    weight="2"
                >
                   Winner table
                </Headline>
                <PrizeTable
                    matrix={selectedRaffle?.matrix}
                />

                <Card
                    className={"w-100 p-3 d-flex flex-column text-center gap-2"}
                >
                    <Headline
                        className={"mb-3"}
                        weight={1}
                    >
                        How to participate
                    </Headline>
                    <div className={"d-flex flex-column w-100 gap-2"}>
                        {selectedRaffle?.task?.conditions && selectedRaffle?.task?.conditions.map((el, i) => {
                            switch (el.type) {
                                case 'telegram_subscribe':
                                    let channels = el?.details?.channels
                                    return (
                                        <div className={"d-flex w-100 flex-column gap-2"}>
                                            {channels && channels.map(el => (
                                                <Button
                                                    before={
                                                        emojiConverter(i + 1)
                                                    }
                                                    onClick={() => subscribeTelegram(el)}
                                                >Subscribe to @{el}</Button>
                                            ))}
                                        </div>

                                    )
                                case 'invite_referrals':
                                    let count = el?.details?.count
                                    return (
                                        <>
                                            <div className={"d-flex w-100 flex-column gap-2"}>
                                                <Button
                                                    before={
                                                        emojiConverter(i + 1)
                                                    }
                                                    onClick={() => {inviteFriends(user)}}
                                                >
                                                    Invite x{count} {count > 1 ? 'friends' : 'friend'}
                                                </Button>
                                            </div>
                                        </>
                                    )
                            }
                        })}
                    </div>
                </Card>

                <Card
                    className={"w-100 p-3"}
                >
                    <div className={"d-flex flex-column gap-2 w-100 text-center"}>
                        <Button
                            loading={loading}
                            onClick={() => checkCompletion()}
                        >
                            🏁 Check tasks and participate
                        </Button>
                        <Caption>OR</Caption>
                        <div className={"d-flex gap-2"}>
                            <Button
                                onClick={() => setModalConfirmOpen(true)}
                                after={
                                    <IconLottyStar
                                        style={{
                                            height: 22,
                                            width: 22
                                        }}
                                    />
                                }
                                className={"w-100"}
                            >
                                Pay 100
                            </Button>
                            <Button
                                loading={telegramStarsLoading}
                                onClick={() => sendTelegramInvoice(1)}
                                after={
                                    <IconTelegramStar
                                        style={{
                                            height: 28,
                                            width: 28
                                        }}
                                    />
                                }
                                className={"w-100"}
                            >
                                Pay 400
                            </Button>
                        </div>
                    </div>
                </Card>
            </div>
        </Drawer>
    )
}

export default ModalRaffle