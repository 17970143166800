import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@telegram-apps/telegram-ui/dist/styles.css';
import {AppRoot} from '@telegram-apps/telegram-ui';
import Route from './Routes';
import React from "react";
import Confetti from "react-confetti-boom";
import {useSelector} from "react-redux";

export const App = () => {
    return (
        <AppRoot id={"app-root"}>
            <Route/>
        </AppRoot>
    )
};
