import {Badge, Tabbar} from "@telegram-apps/telegram-ui";
import {useEffect, useState} from "react";
import React from 'react';
import {Icon24Person} from "../Components/icons/Icon24Person";
import {Icon24Link} from "../Components/icons/Icon24Link";
import {useNavigate} from "react-router-dom";
import {GiRoundStar, GiWallet} from "react-icons/gi";
import { GiEmerald } from "react-icons/gi";
import { GiCowled } from "react-icons/gi";
import { GiThreeFriends } from "react-icons/gi";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentTabbarItem} from "../slices/app/reducer";
import {RiLinkM} from "react-icons/ri";
import {getCounters} from "../slices/app/thunk";

const Footer = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {currentTab, counters} = useSelector((state) => state.App)

    const tabs = [
        {id: 'rewards', text: 'Earn', Icon : <React.Fragment>
                <Badge
                    className={"position-absolute start-50"}
                    mode="critical"
                    type="number"
                >
                    {counters?.count_available_tasks}
                </Badge>
                <GiRoundStar size={26}/>
            </React.Fragment>, path: "/rewards"},
        {id: 'lottys', text: 'Lottys', Icon : <GiEmerald size={26} />, path: "/lottys"},
        {id: 'friends', text: 'Invite', Icon : <RiLinkM size={26} />, path: "/friends"},
        {id: 'wallet', text: 'Wallet', Icon : <GiWallet  size={26}/>, path: "/wallet"}
    ]



    const changeTab = (tab) => {
        if (window.Telegram.WebApp)
            window.Telegram.WebApp.HapticFeedback.impactOccurred('soft')
        navigate(tab.path)
    }

    return (
        <Tabbar className={"footer"} style={{zIndex: 10, position:"sticky"}}>
            {tabs.map((el, i) =>
                <Tabbar.Item className={"tabbar-item"} key={i} text={el.text} selected={el.id === currentTab} onClick={() => changeTab(el)}>
                    {el.Icon}
                </Tabbar.Item>
            )}
        </Tabbar>
    )
}

export default Footer