import {Badge, Button, Caption, Card, Subheadline, Text} from "@telegram-apps/telegram-ui";
import Lottie from "lottie-react";
import giftLottie from "../../assets/lottie/gift.json";
import IconTon from "../icons/IconTon";
import IconLottyStar from "../icons/IconLottyStar";
import React from "react";
import {setSelectedRaffle} from "../../slices/raffles/reducer";
import {useDispatch} from "react-redux";
import {formatNumber} from "../../helpers/format_helper";
import Prizes from "./Raffle/Parts/Prizes";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import chuvak from "../../assets/lottie/dotlottie/chuvak.lottie";

const FinishedLotteryCard = (props) => {
    const {current} = props
    const dispatch = useDispatch()

    const setSelected = (el) => {
        dispatch(setSelectedRaffle(el))
    }

    const first_place = current.winners[0]
    return (
        <Card
            style={{
                borderRadius: 8
            }}
            onClick={() => {setSelected(current)}}
            className={"d-flex align-items-center flex-column gap-2 pb-3 px-3 cursor-pointer w-100"}
            type={"plain"}
        >
            <Lottie
                style={{
                    width: 160,
                    height: 80
                }}
                animationData={giftLottie} loop={false}
            />
            <Subheadline
                weight={2}
            >
                Lotty #{current?.id}
            </Subheadline>
            <div
                style={{
                    minHeight: 78
                }}
                className={"d-flex flex-column justify-content-center align-items-center"}>
                <Prizes prizes={current.prizes} />
            </div>
            {/*<div className={"d-flex flex-column text-center align-items-center"}>*/}
            {/*    <Subheadline weight={2}>*/}
            {/*        1<sup>st</sup> place winner*/}
            {/*    </Subheadline>*/}
            {/*    <div className={"d-flex gap-1 align-items-center"}>*/}
            {/*        <span*/}
            {/*            className={"ton_num"}*/}
            {/*        >@{first_place?.user?.username}</span>*/}
            {/*    </div>*/}

            {/*</div>*/}
            <div className={"d-flex flex-row text-center align-items-center justify-content-center gap-1"}>
                <DotLottieReact
                    src={chuvak}
                    loop
                    autoplay
                    style={{
                        height: 20,
                        width: 20,
                    }}
                />
                <span className={"ton_num"}>
                    {formatNumber(current?.max_participants)}
                </span>
            </div>
            <div className={"mt-2"}>
                <Button
                    mode={"bezeled"}
                    size={'l'}
                    style={{
                        height: 'inherit'
                    }}
                    stretched
                    onClick={() => {setSelected(current)}}
                >
                    <Text weight={2}>
                        Show Winners
                    </Text>

                </Button>
            </div>

        </Card>
    )
}

export default FinishedLotteryCard