import {ModalHeader} from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import {ModalClose} from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose";
import {Icon28Close} from "@telegram-apps/telegram-ui/dist/icons/28/close";
import Lottie from "lottie-react";
import lottieRoulette from "../../assets/lottie/roulette.json";
import {Button, Headline, Image, Modal, Subheadline, Title} from "@telegram-apps/telegram-ui";
import React, {useState} from "react";
import useTaskIcon from "../../hooks/useTaskIcon";
import {checkTaskApi} from "../../helpers/real_api_helper";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import Drawer from "./Drawer";
import {setReward, setShowConfetti} from "../../slices/app/reducer";
import {getCounters} from "../../slices/app/thunk";
import {getTasks} from "../../slices/tasks/thunk";

const ModalTask = (props) => {
    const {modalClose, isOpen, task, headline} = props
    const dispatch = useDispatch()
    const {user} = useSelector(state => state.Auth)
    const [loading, setLoading] = useState(false)
    const {src} = useTaskIcon(task)

    const checkTask = () => {
        dispatch(setShowConfetti(false))
        setLoading(true)
        checkTaskApi(task)
            .then(res => {
                modalClose()
                let reward = [{
                    reward_type: "coin",
                    amount: task.reward,
                    transaction_type: 'credit'
                }]
                dispatch(setReward(reward))
                dispatch(setShowConfetti(true))
                dispatch(getCounters())
                dispatch(getTasks())
                toast.success('Task successfully completed')
            })
            .catch(err => {
                toast.warning(err.data.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const subscribeTelegram = (id) => {
        window.Telegram.WebApp.openTelegramLink('https://t.me/'+id)
    }

    const inviteFriends = () => {
        if (user) {
            const text = 'Welcome to Looty! Invite friends and loot TON'
            const url = `https://t.me/looty/start?startapp=${user.id}`
            window.Telegram.WebApp.openTelegramLink(`https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`)
        }
    }

    return (
        <Drawer
            isOpen={isOpen}
            onClose={modalClose}
        >
            <div className={"d-flex w-100 flex-column gap-3 p-3 justify-content-center align-items-center"}>
                <div className={"task-icon"}>
                    <Image src={src} size={90}></Image>
                </div>
                <Subheadline className={"text-warning"} level={2}>
                    + {task?.reward} ⭐  ️
                </Subheadline>
                <Headline
                    className={"text-center"}
                    level="1"
                    weight="2"
                >
                    {task?.name}
                </Headline>
                <div className={"requirements d-flex flex-column"}>
                    <ol className={"my-0"}>
                        {task?.conditions && task?.conditions.map((el, i) => {
                            switch(el.type) {
                                case 'telegram_subscribe':
                                    let channels = el?.details?.channels.length
                                    return (
                                        <li key={i}>🔗 x{channels} {channels === 1 ? 'Channel' : 'Channels'} subscription</li>
                                    )
                                case 'invite_referrals':
                                    let count = el?.details?.count
                                    return (
                                        <li key={i}>🫂 x{count} Invite {count === 1 ? 'friend' : 'friends'}</li>
                                    )
                            }
                        })}
                    </ol>
                </div>
                <Headline
                    weight={1}
                >
                    {headline}
                </Headline>
                {task?.conditions && task?.conditions.map((el, i) => {
                    switch (el.type) {
                        case 'telegram_subscribe':
                            let channels = el?.details?.channels
                            return (
                                <div className={"d-flex w-100 flex-column gap-2"}>
                                    {channels && channels.map(el => (
                                        <Button
                                            onClick={() => subscribeTelegram(el)}
                                        >Subscribe to @{el}</Button>
                                    ))}
                                </div>

                            )
                        case 'invite_referrals':
                            return (
                                <>

                                    <div className={"d-flex w-100 flex-column gap-2"}>
                                        <Button
                                            onClick={() => {inviteFriends()}}
                                        >Invite friend
                                        </Button>
                                    </div>
                                </>
                            )
                    }
                })}
                <div className={"d-flex flex-column w-100"}>
                    <Button
                        loading={loading}
                        onClick={() => checkTask()}
                        mode={"bezeled"}
                    >
                        Check task
                    </Button>
                </div>
            </div>
        </Drawer>
    )
}

export default ModalTask