import {Button, Headline, LargeTitle, Modal, Title} from "@telegram-apps/telegram-ui";
import {ModalHeader} from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import {ModalClose} from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose";
import {Icon28Close} from "@telegram-apps/telegram-ui/dist/icons/28/close";
import React from "react";
import IconLottyStar from "../../Components/icons/IconLottyStar";
import IconTon from "../../Components/icons/IconTon";
import Drawer from "../../Components/custom/Drawer";

const ModalConfirm = (props) => {
    const {isOpen, modalClose, onConfirm, loading} = props


    return (
        <Drawer
            isOpen={isOpen}
            onClose={modalClose}
            >
            <div className={"text-center d-flex justify-content-center align-items-center flex-column p-2"}>
                <div className={"d-flex mb-4"}>
                    <Title>
                        Are you sure?
                    </Title>
                </div>
                <div className={"d-flex w-100 gap-2"}>
                    <Button
                        onClick={() => modalClose()}
                        stretched
                    >
                        No
                    </Button>
                    <Button
                        loading={loading}
                        onClick={onConfirm}
                        stretched
                    >
                        Yes</Button>
                </div>
            </div>
        </Drawer>
    )
}

export default ModalConfirm