import {Button, Caption, Card, Headline, IconButton, Title} from "@telegram-apps/telegram-ui";
import {Icon20Copy} from "@telegram-apps/telegram-ui/dist/icons/20/copy";
import {IoCopy} from "react-icons/io5";
import Lottie from "lottie-react";
import IconLottyStar from "../../Components/icons/IconLottyStar";
import React from "react";
import star_05 from "../../assets/lottie/dotlottie/star_05.lottie";
import star_17 from "../../assets/lottie/dotlottie/star_17.lottie";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import IconLottyCoin from "../../Components/icons/IconLottyCoin";


const InviteFriend = () => {
    const {user} = useSelector(state => state.Auth)
    const inviteFriends = () => {
        if (user) {
            const text = 'Welcome to Looty! Invite friends and loot TON'
            const url = `https://t.me/looty/start?startapp=${user.id}`
            window.Telegram.WebApp.openTelegramLink(`https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`)
        }
    }

    const copyInviteLink = () => {
        const url = `https://t.me/looty/start?startapp=${user.id}`
        navigator.clipboard.writeText(url).then(res => {
            toast('Copied')
        })
    }

    return (
        <Card className={"d-block w-100"}>
            <div className={"d-flex w-100 flex-column p-2 gap-2"}>
                <div className={"d-flex flex-column gap-2"}>
                    <div className={"d-flex align-items-center gap-2"}>
                        <div className={"sticker"}>
                            <DotLottieReact
                                style={{
                                    width: 100,
                                    height: 80
                                }}
                                src={star_05}
                                loop
                                autoplay
                            />
                        </div>
                        <div className={"d-flex flex-column"}>
                            <Headline weight={1}>
                                Invite a friend
                            </Headline>
                            <Caption className={"d-flex gap-1 align-items-center"}>
                                + 1000  <IconLottyCoin className={"friend-reward-icon"} /> to you and your friend
                            </Caption>
                        </div>
                    </div>
                    <div className={"d-flex align-items-center gap-2"}>
                        <div className={"sticker"}>
                            <DotLottieReact
                                style={{
                                    width: 100,
                                    height: 80
                                }}
                                src={star_17}
                                loop
                                autoplay
                            />
                        </div>
                        <div className={"d-block"}>
                            <Headline weight={1}>
                                Invite a friend with Telegram Premium
                            </Headline>
                            <Caption className={"d-flex gap-1 align-items-center"}>
                                + 50 <IconLottyStar className={"friend-reward-icon"} /> to you and your friend
                            </Caption>
                        </div>

                    </div>
                </div>
                <div className={"d-flex gap-3 align-items-center"}>
                    <Button
                        onClick={() => {inviteFriends()}}
                        className={"w-100"}
                    >
                        Invite friend
                    </Button>
                    <IconButton
                        onClick={() => copyInviteLink()}
                        size={"l"}
                        mode={"bezeled"}
                    >
                        <IoCopy size={28}/>
                    </IconButton>
                </div>

            </div>
        </Card>
    )
}

export default InviteFriend