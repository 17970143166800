import {
    setLoading,
    setRaffleMeta,
    setRaffles, setSelectedInnerType,
    setSelectedTab,
    setShowSkeleton,
    setTopRaffles,
    setTopRaffleSkeleton,
    setWinners,
    setWinnersMeta
} from "./reducer";
import {getRafflesApi, getRaffleWinnersApi, participateRaffleApi} from "../../helpers/real_api_helper";
import {toast} from "react-toastify";

export const getRaffles = (page = 1, type, innerType = 'all', append = false) => async (dispatch) => {
    try {
        dispatch(setShowSkeleton(true));
        dispatch(setLoading(true));
        const res = await getRafflesApi(page, type, innerType);
        dispatch(setRaffles({
            data: res.data,
            append
        }));
        dispatch(setRaffleMeta(res.meta));
    } catch (err) {
        console.log(err);
    } finally {
        dispatch(setLoading(false));
        dispatch(setShowSkeleton(false));
    }
};

export const getTopRaffles = (page = 1, type, innerType = 'all') => async (dispatch) => {
    try {
        dispatch(setTopRaffleSkeleton(false))
        const res = await getRafflesApi(page, type, innerType, true);
        dispatch(setTopRaffles(res.data));
    } catch (err) {
        console.log(err);
    } finally {
    }
};

export const getRaffleWinners = (page = 1, raffle, append = false) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const res = await getRaffleWinnersApi(page, raffle);
        dispatch(setWinners({
            data: res.data,
            append
        }));
        dispatch(setWinnersMeta(res.meta));
    } catch (err) {
        console.log(err);
    } finally {
        dispatch(setLoading(false));
    }
};


export const participateToRaffle = (data, raffle) => async (dispatch) =>  {
    try {
        let res = participateRaffleApi(raffle, data)
        dispatch(getTopRaffles(1, 'active'))
        dispatch(getRaffles(1, 'active', 'joined'))
        dispatch(setSelectedTab('active'))
        dispatch(setSelectedInnerType('joined'))
        return res;
    }
    catch (err) {

    }
    finally {

    }
}