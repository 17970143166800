import {getTasksApi} from "../../helpers/real_api_helper";
import {setShowSkeleton, setTasks} from "./reducer";


export const getTasks = () => async (dispatch) => {
    try {
        dispatch(setShowSkeleton(true))
        getTasksApi()
            .then(res => {
                dispatch(setTasks(res.data))
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                dispatch(setShowSkeleton(false))
            })
    }
    catch (error) {

    }
}