import {authTgUser, getCountsApi, getTopUsersApi} from "../../helpers/real_api_helper";
import {setCurrentUser, setTopUsers} from "./reducer";
export const getTopUsers = () => async (dispatch) => {
    try {
        const res = await getTopUsersApi();
        dispatch(setTopUsers(res.data));
        dispatch(setCurrentUser(res.currentUser));
    } catch (error) {
        console.error(error);
    }
}

